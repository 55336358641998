import { useAuth0 } from "@auth0/auth0-react";
import { ROUTES } from "@config";
import { fillRouteWithSlugs } from "@utils";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Loading } from "./Loading";

export const AuthPage = ({ type }: { type: "login" | "signup" }) => {
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const email = searchParams.get("email");

  useEffect(() => {
    if (!isLoading) {
      if (isAuthenticated) {
        // Let callback define where to send the user
        navigate(fillRouteWithSlugs(ROUTES.CALLBACK));
      } else {
        loginWithRedirect({
          screen_hint: type,
          login_hint: email || "",
        });
      }
    }
  }, [isAuthenticated, isLoading]);

  return <Loading />;
};
