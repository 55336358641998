import { DocumentStatus } from "@types";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { templatesWithUpload } from "src/config";
import {
  useDeleteApproverMutation,
  useGetDocumentApproversQuery,
  useGetDocuments,
  useGetUser,
  useGetUserById,
  usePatchDocumentVersionMutation,
  usePostApproverMutation,
} from "../../../../../hooks";
import {
  BaseUser,
  Document,
  DocumentVersion,
  DocumentVersionApprover,
  TEMPLATE_TYPE,
} from "../../../../../stores/models";
import {
  getDependantTemplates,
  getDocTypeStatus,
  getDocVersionRevisionNumber,
  getDocVersionsWithStatus,
  getRecentlyUpdatedElementIds,
} from "../../../../../utils";
import ApproverContent from "./ApproverContent";
import ApproversTabContentLoading from "./ApproversTabContentLoading";
import AuthorContent from "./AuthorContent";
import CompleteScreen from "./CompleteScreen";
import { NotInvolvedContent } from "./NotInvolvedContent";

const requiredNumberOfApprovers = 2;

export const ApproversTabContent = ({
  selectedDocVersion,
  selectedDoc,
  deviceId,
  generatedDoc,
  templateId,
}: {
  selectedDoc: Document;
  selectedDocVersion: DocumentVersion;
  deviceId: string;
  templateId: TEMPLATE_TYPE;
  generatedDoc?: string;
}) => {
  const { data: user } = useGetUser();
  const { data: docVersionAuthor } = useGetUserById({
    userId: selectedDocVersion?.createdBy,
  });

  const { orgId = "" } = useParams();
  const { data: documents } = useGetDocuments(orgId, deviceId);

  const { data: approvers } = useGetDocumentApproversQuery({
    orgId,
    documentId: selectedDocVersion.documentId,
    versionId: selectedDocVersion.id,
    deviceId,
  });

  const [enableApproversEditing, setEnableApproversEditing] = useState(true);
  const [localRevisionSummary, setLocalRevisionSummary] = useState<
    string | null
  >(null);

  const [selectedUsers, setSelectedUsers] = useState<Array<BaseUser | null>>(
    []
  );
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (approvers) {
      setSelectedUsers(approvers.map((approver) => approver.user));
    }

    if (approvers?.length === requiredNumberOfApprovers) {
      setEnableApproversEditing(false);
    }
  }, [approvers]);

  if (!selectedDocVersion) {
    return <ApproversTabContentLoading />;
  }

  const approverMutation = usePostApproverMutation({
    orgId,
    documentId: selectedDocVersion.documentId,
    versionId: selectedDocVersion.id,
    deviceId,
  });

  const removeApproverMutation = useDeleteApproverMutation({
    orgId,
    documentId: selectedDocVersion.documentId,
    versionId: selectedDocVersion.id,
    deviceId,
  });

  const currentUserApprover = approvers?.find(
    (approver) => approver.userId === user?.id
  );

  let approver: DocumentVersionApprover | null = null;

  if (currentUserApprover) {
    approver = currentUserApprover;
  }

  const patchDocumentMutation = usePatchDocumentVersionMutation();

  const handleSave = async (
    approvers: DocumentVersionApprover[],
    selectedUsers: BaseUser[],
    revisionSummary: string
  ) => {
    setEnableApproversEditing(false);
    setSaving(true);

    const newUsers = selectedUsers.filter(
      (s) => !approvers.find((a) => a.user.id === s?.id)
    );
    const removedUsers = approvers.filter(
      (a) => !selectedUsers.find((s) => s?.id === a.user.id)
    );

    // For each approver that got removed, remove the approver from the backend and for each approver that got added, add the approver to the backend
    const deletedUsers = await Promise.all(
      removedUsers.map(async (approver) => {
        const { status } = await removeApproverMutation.mutateAsync({
          approverId: approver.id,
        });
        return status;
      })
    );

    if (deletedUsers.every((result) => result === 200)) {
      await Promise.all(
        newUsers.map(async (user) => {
          await approverMutation.mutateAsync({
            userId: user.id,
          });
        })
      );
    }

    const versionNeedsSnapshot = !templatesWithUpload.includes(templateId);

    let snapshot = selectedDocVersion.snapshot;

    if (versionNeedsSnapshot && !snapshot) {
      if (!generatedDoc) {
        throw new Error(
          "To submit this revision the generated document is required but missing."
        );
      }
      snapshot = generatedDoc;
    }

    if (!snapshot && versionNeedsSnapshot) {
      throw new Error("Snapshot needs to be defined but is missing.");
    }

    if (revisionSummary !== selectedDocVersion.revisionSummary) {
      await patchDocumentMutation.mutateAsync({
        orgId,
        docId: selectedDocVersion.documentId,
        docVersionId: selectedDocVersion.id,
        deviceId,
        data: {
          revisionSummary,
          ...(versionNeedsSnapshot &&
          snapshot &&
          snapshot !== selectedDocVersion.snapshot
            ? { snapshot }
            : {}),
        },
      });
    }
    setSaving(false);
  };

  if (!user || approvers === undefined || !docVersionAuthor || !documents) {
    return <ApproversTabContentLoading />;
  }

  const secondToLastVersion = getDocVersionsWithStatus({
    document: selectedDoc,
    user,
    documents,
    templateId,
    orgId,
  })[1];

  const recentlyUpdatedElementIds = getRecentlyUpdatedElementIds(
    selectedDocVersion,
    secondToLastVersion
  );

  const dependentDocs = getDependantTemplates(recentlyUpdatedElementIds).filter(
    ([template, _]) => {
      const status = getDocTypeStatus({
        documents,
        templateId: template as TEMPLATE_TYPE,
        user,
        orgId,
      });

      const isUnapprovedOrLive =
        status === DocumentStatus.UNAPPROVED || status === DocumentStatus.LIVE;
      const isNotSelf = template !== templateId;

      return isUnapprovedOrLive && isNotSelf;
    }
  );

  const userIsAuthor = selectedDocVersion.createdBy === user.id;

  if (approvers && selectedDocVersion.revision !== null) {
    return (
      <CompleteScreen
        approvers={approvers}
        author={docVersionAuthor}
        docVersion={selectedDocVersion}
        revision={getDocVersionRevisionNumber(
          selectedDocVersion,
          selectedDoc.versions
        )}
        revisionSummary={selectedDocVersion.revisionSummary}
      />
    );
  }

  if (!approver && !userIsAuthor) {
    return (
      <NotInvolvedContent
        approvers={approvers}
        docVersionAuthor={docVersionAuthor}
        requiredNumberOfApprovers={requiredNumberOfApprovers}
      />
    );
  }

  if (approver && !userIsAuthor) {
    return (
      <ApproverContent
        approvers={approvers}
        revisionSummary={selectedDocVersion.revisionSummary}
        selectedDocVersion={selectedDocVersion}
        approver={approver}
      />
    );
  }

  if (userIsAuthor) {
    return (
      <AuthorContent
        selectedUsers={selectedUsers}
        setSelectedUsers={setSelectedUsers}
        revisionSummary={
          localRevisionSummary ?? selectedDocVersion.revisionSummary
        }
        saving={saving}
        setRevisionSummary={setLocalRevisionSummary}
        enableApproversEditing={enableApproversEditing}
        setEnableApproversEditing={setEnableApproversEditing}
        author={docVersionAuthor}
        handleSave={handleSave}
        savedRevisionSummary={selectedDocVersion.revisionSummary}
        savedApprovers={approvers}
        dependentDocs={dependentDocs}
      />
    );
  }
};

export default ApproversTabContent;
