import { useAuth0 } from "@auth0/auth0-react";
import {
  BuildOutlined,
  ChangeCircleOutlined,
  GroupsOutlined,
  Person2Outlined,
  PowerSettingsNewOutlined,
  ReceiptLongOutlined,
  WorkspacePremiumOutlined,
} from "@mui/icons-material";
import { Divider, Menu, MenuItem, Typography } from "@mui/material";

import { useSnackbar } from "@components";
import { useCreateBillingSessionMutation } from "@hooks";
import { Organization, Plan, User } from "@models";
import { fillRouteWithSlugs, getSubscription } from "@utils";
import { useParams } from "react-router-dom";
import { ROUTES } from "src/config";
import { ROUTE_SLUGS } from "src/config/navigation/routes";
import { SwitchOrgMenu } from "./SwitchOrgMenu";
import { UserCard } from "./UserCard";

type Props = {
  anchorUserMenu: HTMLElement | null;
  isOpen: boolean;
  close: () => void;
  user: User;
  org: Organization;
  anchorOrgMenu: Element | null;
  isOrgMenuOpen: boolean;
  closeOrgMenu: () => void;
  clickOrgMenuIcon: (e: React.MouseEvent<Element, MouseEvent>) => void;
};

export const UserMenu = ({
  anchorUserMenu,
  isOpen,
  close,
  org,
  user,
  anchorOrgMenu,
  isOrgMenuOpen,
  closeOrgMenu,
  clickOrgMenuIcon,
}: Props) => {
  const { logout } = useAuth0();
  const { orgId = "" } = useParams();
  const { showSnackbar } = useSnackbar();
  const { mutateAsync: createBillingSession } =
    useCreateBillingSessionMutation();
  if (!user) return null;
  const { products: activeProducts } = getSubscription(org);

  const handleGoToBilling = async () => {
    const billingUrl = await createBillingSession(orgId);
    if (billingUrl) {
      window.open(billingUrl, "_blank");
    } else {
      showSnackbar(
        "Failed to create billing session. Please try again or contact support."
      );
    }
  };

  return (
    <Menu
      id="user-menu"
      anchorEl={anchorUserMenu}
      open={isOpen}
      onClose={close}
      onClick={close}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
      disablePortal
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      slotProps={{
        paper: {
          sx: {
            maxHeight: 300,
          },
        },
      }}
    >
      <Typography variant="body1" className="px-4 font-bold flex gap-3">
        <Person2Outlined />
        {user.firstName} {user.lastName}
      </Typography>
      <Typography variant="body2" className="px-4 flex gap-3.5 items-center">
        <GroupsOutlined />
        {org?.name}
      </Typography>
      <Divider className="my-2" />

      <UserCard
        key={orgId}
        to={fillRouteWithSlugs(ROUTES.SUBSCRIPTION, {
          [ROUTE_SLUGS.ORG_ID]: orgId,
        })}
        text={"Plans"}
        icon={<WorkspacePremiumOutlined color="primary" />}
      />

      {!activeProducts.includes(Plan.FREE) && (
        <UserCard
          onClick={() => {
            close();
            handleGoToBilling();
          }}
          text={"Billing"}
          icon={<ReceiptLongOutlined />}
        />
      )}
      {orgId && (
        <UserCard
          key={org?.name}
          to={fillRouteWithSlugs(ROUTES.MANAGE_ORGANIZATION, {
            [ROUTE_SLUGS.ORG_ID]: orgId,
          })}
          text={"Manage Organization"}
          icon={<BuildOutlined />}
          onMouseEnter={closeOrgMenu}
          onClick={close}
        />
      )}
      <MenuItem
        onMouseEnter={(e) => e && clickOrgMenuIcon(e)}
        onClick={(e) => {
          e.stopPropagation();
          close();
        }}
        sx={{
          zIndex: 20,
          "&:hover": {
            bgcolor: "rgba(0, 0, 0, 0.04)",
            cursor: "pointer",
          },
        }}
        className="gap-3"
      >
        <ChangeCircleOutlined />
        <Typography variant="body1" className="text-wrap">
          Switch Workspace
        </Typography>
      </MenuItem>
      <SwitchOrgMenu
        anchorOrgMenu={anchorOrgMenu}
        isOrgMenuOpen={isOrgMenuOpen}
        closeUserMenu={close}
        closeOrgMenu={closeOrgMenu}
        user={user}
      />
      <UserCard
        onClick={() => logout({ returnTo: window.location.origin })}
        key="logout"
        text="Logout"
        onMouseEnter={closeOrgMenu}
        icon={<PowerSettingsNewOutlined />}
      />
    </Menu>
  );
};
