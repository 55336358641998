import {
  Device,
  Document,
  DocumentAnswer,
  DocumentVersion,
  TEMPLATE_TYPE,
} from "@models";
import { TemplateElement } from "@types";
import {
  getAvailableDataKeys,
  getDocumentAnswerMap,
  getDocumentStepsFromConfig,
  getFilteredSteps,
} from "src/utils/documents";

export const isStepComplete = (
  step: TemplateElement,
  answer: Partial<DocumentAnswer>
): boolean => {
  if (!step.required) {
    return true;
  }

  if (step.element.type === "fileUpload") {
    return !!answer?.answerFileId;
  }

  const hasNonEmptyAnswer = !!answer?.answer && answer.answer.trim() !== "";
  const hasDefaultValue = !!step.element.options?.default;

  return hasNonEmptyAnswer || hasDefaultValue;
};

export const allStepsCompleted = (
  document: Document,
  documentVersion: DocumentVersion,
  device: Device,
  documents: Document[]
) => {
  const values = Object.entries(getDocumentAnswerMap(documentVersion));

  const steps = getDocumentStepsFromConfig(document.name as TEMPLATE_TYPE);

  const dataKeysWithValue = getAvailableDataKeys(documents);

  const filteredSteps = getFilteredSteps(steps, device, dataKeysWithValue);

  return filteredSteps.every((step) => {
    const [_, answer] = values.find(([k]) => k === step.id) || [];

    return isStepComplete(
      step,
      step.element.type === "fileUpload" ? { answerFileId: answer } : { answer }
    );
  });
};
