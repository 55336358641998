import { ArrowRightAlt } from "@mui/icons-material";
import { AccordionDetails, Card, Typography } from "@mui/material";
import { useUpdateSearchParams } from "@utils";
import {
  ASSISTANT_CONFIG,
  ROADMAP_CARDS,
  ROADMAP_TASKS,
  RoadmapTask,
  theme,
} from "../../../config";
import { TEMPLATE_TYPE } from "../../../stores/models";

type Props = {
  handleCloseCardDialog: () => void;
  task: RoadmapTask;
  incompleteDocuments: TEMPLATE_TYPE[] | undefined;
};

export const TaskAccordionDetails = ({
  handleCloseCardDialog,
  task,
  incompleteDocuments,
}: Props) => {
  const updateSearchParams = useUpdateSearchParams();

  return (
    <AccordionDetails className="mr-8">
      <div
        className="flex flex-col gap-2"
        data-testid={`${task?.id}-dependencies`}
      >
        <div className="flex flex-col">
          <Typography variant="body1">Document Dependencies</Typography>
          <Typography variant="caption">
            Complete these documents to unlock this task.
          </Typography>
        </div>
        {incompleteDocuments?.map((c, index) => {
          const task = ROADMAP_TASKS.find((t) => t.id === c);
          const card = ROADMAP_CARDS.find((c) => c.id === task?.card);

          return (
            <Card
              data-testid={`${task?.id}-dependency-${index}`}
              key={`${card?.id}-${task?.id}`}
              onClick={() => {
                handleCloseCardDialog();
                updateSearchParams({
                  card: card?.title || "",
                  task: task?.id || "",
                });
              }}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "10px",
                border: `1px solid ${theme.palette.grey[400]}`,
                boxShadow: "none",
                padding: "10px",
                backgroundColor: "transparent",
                width: "100%",
                cursor: "pointer",

                "& #go-to-task": {
                  transition: "transform 0.2s ease-in-out",
                  transform: "translateX(0px)",
                },

                "&:hover #go-to-task": {
                  transform: "translateX(3px)",
                  color: theme.palette.primary.main,
                },
              }}
            >
              {ASSISTANT_CONFIG[c].docName}
              <Typography
                variant="caption"
                className="flex items-center gap-1"
                id="go-to-task"
              >
                go to task
                <ArrowRightAlt />
              </Typography>
            </Card>
          );
        })}
      </div>
    </AccordionDetails>
  );
};
