import { useEffect, useState } from "react";

import { useGetDocuments, useGetFile } from "@hooks";
import { TEMPLATE_TYPE } from "@models";
import { CloudUploadOutlined } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import { DocumentViewerSkeleton } from "src/components/Skeletons";
import DocInfoDrawer from "../docInfoDrawer/DocInfoDrawer";
import useSelectDocumentVersion from "../hooks/useSelectDocument";
import { BaseAnimation } from "./BtnAnimation";
import { FileDocViewerTopBar } from "./FileDocViewerTopBar";
import FileDownload from "./FileDownload";
import FileUpload from "./FileUpload";

export const FileDocViewer = ({
  deviceId,
  templateId,
}: {
  deviceId: string;
  templateId: TEMPLATE_TYPE;
}) => {
  const { orgId = "" } = useParams();
  // If the template id changes reset the selected document version otherwise the old selected document version will be used for the new template
  useEffect(() => {
    setSelectedDocVersionId(undefined);
    setUploadStatus("idle");
  }, [templateId]);

  const [showDocInfoDrawer, setShowDocInfoDrawer] = useState(false);

  const { data: documents } = useGetDocuments(orgId, deviceId);

  const toggleDrawer = () => setShowDocInfoDrawer((prev) => !prev);

  const [uploadStatus, setUploadStatus] = useState<
    "error" | "idle" | "success" | "pending"
  >("idle");

  const {
    setSelectedDocVersionId,
    selectedDocVersion,
    selectedDoc,
    handleCreateNewVersionWithFile,
    selectLoading,
    selectDocumentVersion,
  } = useSelectDocumentVersion({
    documents,
    templateId,
    setUploadStatus,
    deviceId,
  });
  const fileId = selectedDocVersion?.fileId || "";

  const { data: file, isSuccess } = useGetFile({
    orgId,
    fileId,
  });

  const handleFileDownload = () => {
    if (isSuccess && file?.data) {
      const url = window.URL.createObjectURL(file.data);

      const tempLink = window.document.createElement("a");
      tempLink.href = url;
      tempLink.setAttribute("download", `${templateId}`);

      window.document.body.appendChild(tempLink);
      tempLink.click();

      window.document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(url);
    }
  };

  const [editConfirmationOpen, setEditConfirmationOpen] = useState(false);

  const handleEditClick = () => {
    setEditConfirmationOpen(true);
  };

  const handleEditCancel = () => {
    setEditConfirmationOpen(false);
  };

  if (!selectedDoc) {
    return <DocumentViewerSkeleton />;
  }

  const hasAnyVersionsYet = selectedDoc?.versions.length || 0 > 0;

  return (
    <div className=" flex w-full flex-1 flex-col gap-y-2">
      {selectedDoc && selectedDocVersion && documents && (
        <FileDocViewerTopBar
          documents={documents}
          selectedDoc={selectedDoc}
          selectedDocVersion={selectedDocVersion}
          selectDocumentVersion={selectDocumentVersion}
          showDocInfoDrawer={showDocInfoDrawer}
          selectLoading={selectLoading}
          handleEditClick={handleEditClick}
          handleEditCancel={handleEditCancel}
          editConfirmationOpen={editConfirmationOpen}
          setEditConfirmationOpen={setEditConfirmationOpen}
          toggleDrawer={toggleDrawer}
          handleCreateNewVersionWithFile={handleCreateNewVersionWithFile}
          templateId={templateId}
        />
      )}

      {hasAnyVersionsYet ? (
        selectLoading ? (
          <BaseAnimation
            Icon={CloudUploadOutlined}
            text={`Upload in progress...`}
            buttonText={"Uploading"}
            ButtonIcon={CloudUploadOutlined}
            animated={false}
            isLoading={true}
          />
        ) : (
          <FileDownload
            handleFileDownload={handleFileDownload}
            templateId={templateId}
          />
        )
      ) : (
        <FileUpload
          uploadStatus={uploadStatus}
          handleFileChange={(e) =>
            handleCreateNewVersionWithFile(selectedDoc, e)
          }
        />
      )}
      {selectedDocVersion && selectedDoc && (
        <DocInfoDrawer
          selectedDoc={selectedDoc}
          isOpen={showDocInfoDrawer}
          onDrawerClose={toggleDrawer}
          onDrawerOpen={toggleDrawer}
          selectedDocVersion={selectedDocVersion}
          deviceId={deviceId}
          templateId={templateId}
        />
      )}
    </div>
  );
};
