import { Button, Container, Typography } from "@mui/material";
import {
  isRouteErrorResponse,
  useNavigate,
  useRouteError,
} from "react-router-dom";

export default function Error() {
  const navigate = useNavigate();
  const error = useRouteError();

  return (
    <Container
      maxWidth="md"
      className="items-center justify-center flex flex-auto flex-col h-full min-h-[300px]"
    >
      <Typography variant="h1" className="mb-2">
        Looks like an error has occurred.
      </Typography>
      {isRouteErrorResponse(error) && (
        <Typography variant="body2">{error.data}</Typography>
      )}
      <Typography variant="body2">
        Email support@formly.ai if assistance required.
      </Typography>
      <Button
        className="mt-6"
        variant="contained"
        onClick={() => navigate("/")}
      >
        Bring Me Home
      </Button>
    </Container>
  );
}
