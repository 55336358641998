import { useGetFile, useUploadFileMutation } from "@hooks";
import { FileUploadElement, StepValue } from "@types";
import { useState } from "react";
import { useParams } from "react-router-dom";
import UploadContainer from "src/components/Form/Elements/UploadContainer";

const FileUpload = ({
  element,
  answerFileId,
  onChange,
}: {
  element: FileUploadElement;
  answerFileId: string | undefined | null;
  onChange: (id: string, value: StepValue) => void;
}) => {
  const { orgId = "" } = useParams();
  const { data: file, isLoading: isLoadingFile } = useGetFile({
    orgId,
    fileId: answerFileId || "",
  });

  const uploadFileMutation = useUploadFileMutation();

  let savedImgUrl = "";

  if (file?.data) {
    savedImgUrl = URL.createObjectURL(file?.data);
  }

  const [fileToPreview, setFileToPreview] = useState<File | undefined>();

  const handleChange = async (file: File) => {
    const fileId = await uploadFileMutation.mutateAsync({
      orgId,
      file,
    });

    setFileToPreview(file);
    onChange(element.id, { answerFileId: fileId.id });
    return fileId;
  };

  const imgSrc = fileToPreview
    ? URL.createObjectURL(fileToPreview)
    : savedImgUrl;

  return (
    <UploadContainer
      onChange={handleChange}
      element={element}
      allowedFileTypes={element.options.allowedFileTypes}
      imgSrc={imgSrc}
      isLoading={isLoadingFile}
    />
  );
};

export default FileUpload;
