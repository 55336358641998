import { useAuth0 } from "@auth0/auth0-react";
import { getUserAsync } from "@hooks";
import { captureSentryMessage, fillRouteWithSlugs } from "@utils";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTE_SLUGS, ROUTES } from "src/config/navigation/routes";
import { Loading } from "src/pages/Loading";
import { authHelper } from "src/stores/helpers";
import { User } from "src/stores/models/user";
import { getUserHomeOrg } from "src/utils/user";

const getReturnToLink = () => {
  // Check if there's a saved returnTo in sessionStorage
  const savedReturnTo = sessionStorage.getItem("returnTo");
  sessionStorage.removeItem("returnTo");
  return savedReturnTo;
};

/**
 * Decides where to send user before hitting protected route
 */
export const NavigateToHomeOrReturnTo = () => {
  const { isLoading, isAuthenticated, user, getAccessTokenSilently } =
    useAuth0();
  const navigate = useNavigate();

  const navigateToReturnTo = (user: User) => {
    const returnTo = getReturnToLink();
    if (!returnTo) return false;

    let navigated = false;
    user.organizationMemberships.forEach((orgMember) => {
      if (returnTo.includes(orgMember.organizationId)) {
        navigate(returnTo);
        navigated = true;
        return;
      }
    });
    return navigated;
  };

  useEffect(() => {
    if (!isLoading && user && isAuthenticated) {
      authHelper.setAccessTokenSilently(getAccessTokenSilently);
      const getUser = async () => {
        const user = await getUserAsync();
        if (navigateToReturnTo(user)) return;
        const homeOrgId = user ? getUserHomeOrg(user)?.id : "";
        navigate(
          fillRouteWithSlugs(ROUTES.DEVICE_MANAGEMENT, {
            [ROUTE_SLUGS.ORG_ID]: homeOrgId,
          })
        );
      };
      getUser();
    }
  }, [user, isAuthenticated]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (isLoading) {
        captureSentryMessage("Navigation loading took more than 5 seconds", {
          level: "warning",
          tags: {
            component: "NavigateToHomeOrReturnTo",
          },
          captureContext: {
            isLoading,
            isAuthenticated,
            user,
          },
        });
      }
    }, 5000);
    return () => clearTimeout(timeout);
  }, []);

  return <Loading />;
};
