import { User } from "@models";
import * as Sentry from "@sentry/react";
import { Crisp } from "crisp-sdk-web";
import { PostHog } from "posthog-js/react";

export const getOrgFromUser = (user?: User, orgId?: string) => {
  if (!user || !orgId) return undefined;
  const orgMember = user.organizationMemberships.find(
    (o) => o.organizationId === orgId
  );
  return orgMember?.organization;
};

export const getUserHomeOrg = (user: User) => {
  const orgMember = user.organizationMemberships.find((o) => o.homeOrg);
  return orgMember?.organization;
};

export const setTracingContext = (user: User, postHog?: PostHog) => {
  Crisp.user.setEmail(user.email);
  Crisp.user.setNickname(user.firstName + " " + user.lastName);
  Sentry.setUser({
    email: user.email,
    name: user.firstName + " " + user.lastName,
  });
  if (postHog) {
    postHog.identify(user.id, {
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
    });
  }
};
