import { Document, User } from "@models";
import {
  EditOutlined,
  HistoryEdu,
  LockOutlined,
  NoteAddOutlined,
  TaskOutlined,
} from "@mui/icons-material";
import { fillRouteWithSlugs, getDocTypeStatus } from "@utils";
import { Link, useParams } from "react-router-dom";
import { ROUTE_SLUGS } from "src/config/navigation/routes";
import { ROUTES } from "../../../config";
import { DocumentStatus, TemplateAssistant } from "../../../types";
import { DocExplorerLeafContent } from "./DocExplorerLeafContent";

const DocExplorerLeaf = ({
  template,
  deviceId,
  documents,
  user,
  highlightedText,
}: {
  template: TemplateAssistant;
  deviceId: string;
  user: User;
  documents: Document[];
  highlightedText?: React.ReactNode;
}) => {
  const { orgId = "" } = useParams();

  const docStatus = getDocTypeStatus({
    documents,
    templateId: template.id,
    user,
    orgId,
  });

  // Default: Locked if unsure what to do with item
  if (docStatus === DocumentStatus.LOCKED || !docStatus) {
    return (
      <DocExplorerLeafContent
        template={template}
        title="Locked"
        disabled
        Icon={LockOutlined}
        color="inherit"
        highlightedText={highlightedText}
        data-testid={`${template.id}-locked`}
      />
    );
  }

  if (docStatus === DocumentStatus.AVAILABLE) {
    return (
      <Link
        to={fillRouteWithSlugs(ROUTES.QMS_OPEN_DOC, {
          [ROUTE_SLUGS.ORG_ID]: orgId,
          [ROUTE_SLUGS.DEVICE_ID]: deviceId,
          [ROUTE_SLUGS.TEMPLATE_ID]: template.id,
        })}
        className={`text-body no-underline`}
        data-testid={`${template.id}-available`}
      >
        <DocExplorerLeafContent
          template={template}
          Icon={NoteAddOutlined}
          title="Static Document"
          color="inherit"
          highlightedText={highlightedText}
        />
      </Link>
    );
  }

  if (docStatus === DocumentStatus.DRAFT) {
    return (
      <Link
        to={fillRouteWithSlugs(ROUTES.QMS_OPEN_DOC, {
          [ROUTE_SLUGS.ORG_ID]: orgId,
          [ROUTE_SLUGS.DEVICE_ID]: deviceId,
          [ROUTE_SLUGS.TEMPLATE_ID]: template.id,
        })}
        className={`text-body no-underline`}
        data-testid={`${template.id}-draft`}
      >
        <DocExplorerLeafContent
          template={template}
          title="Draft"
          Icon={EditOutlined}
          color="warning"
          highlightedText={highlightedText}
        />
      </Link>
    );
  }

  if (docStatus === DocumentStatus.UNAPPROVED) {
    return (
      <Link
        to={fillRouteWithSlugs(ROUTES.QMS_OPEN_DOC, {
          [ROUTE_SLUGS.ORG_ID]: orgId,
          [ROUTE_SLUGS.DEVICE_ID]: deviceId,
          [ROUTE_SLUGS.TEMPLATE_ID]: template.id,
        })}
        className={`text-body no-underline`}
        data-testid={`${template.id}-unapproved`}
      >
        <DocExplorerLeafContent
          template={template}
          title="Unapproved"
          Icon={HistoryEdu}
          highlightedText={highlightedText}
        />
      </Link>
    );
  }

  // Render completed documents
  return (
    <Link
      to={fillRouteWithSlugs(ROUTES.QMS_OPEN_DOC, {
        [ROUTE_SLUGS.ORG_ID]: orgId,
        [ROUTE_SLUGS.DEVICE_ID]: deviceId,
        [ROUTE_SLUGS.TEMPLATE_ID]: template.id,
      })}
      className={`text-body no-underline`}
      data-testid={`${template.id}-live`}
    >
      <DocExplorerLeafContent
        template={template}
        title="Live"
        Icon={TaskOutlined}
        color="success"
        highlightedText={highlightedText}
      />
    </Link>
  );
};

export default DocExplorerLeaf;
