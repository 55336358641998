import { DocumentAnswer, DocumentVersion } from "@models";
import { TemplateElement } from "@types";
import { useUpdateSearchParams } from "@utils";
import { isStepComplete } from "src/utils/step";

export const ProgressBubbles = ({
  filteredSteps,
  activeStep,
  documentVersion,
}: {
  filteredSteps: TemplateElement[];
  activeStep: TemplateElement;
  documentVersion: DocumentVersion;
}) => {
  const updateSearchParams = useUpdateSearchParams();

  return (
    <div className="flex flex-wrap gap-x-3">
      {filteredSteps.map((step, index) => {
        const stepEnabled =
          documentVersion.answers.find(
            (a: DocumentAnswer) =>
              a.element === filteredSteps[index - 1]?.id &&
              isStepComplete(filteredSteps[index - 1], a)
          ) || index === 0;
        const stepCompleted = documentVersion.answers.find(
          (a: DocumentAnswer) =>
            a.element === filteredSteps[index].id &&
            isStepComplete(filteredSteps[index], a)
        );

        const backgroundColor =
          step.id === activeStep.id
            ? "bg-[#0089F9]"
            : stepCompleted
              ? "bg-[green]"
              : "bg-[gray]";
        return (
          <div
            key={step.id}
            id={step.id}
            data-testid={`step-${step.id}`}
            className={`group ${
              stepEnabled ? "cursor-pointer" : "cursor-not-allowed"
            } py-2`}
            onClick={() => {
              if (stepEnabled) {
                updateSearchParams({ step: step.id });
              }
            }}
          >
            <div
              className={`h-[10px] w-[30px] rounded-xl group-hover:opacity-80 ${backgroundColor}`}
            ></div>
          </div>
        );
      })}
    </div>
  );
};
