import {
  BillingInterval,
  ComponentConfigurationType,
  ComponentType,
  Device,
  Document,
  DocumentAnswer,
  DocumentVersion,
  Entitlement,
  EntitlementType,
  Organization,
  OrganizationMemberWithOrganization,
  Product,
  Subscription,
  SubscriptionItem,
  TEMPLATE_TYPE,
  User,
} from "@models";

export const mockSoftwareDevice: Device = {
  id: "device-1",
  name: "Device 1",
  description: "Device 1 description",
  components: [
    {
      id: "component-1",
      name: "Component 1",
      description: "Component 1 description",
      type: ComponentType.SOFTWARE,
      configuration: [
        {
          type: ComponentConfigurationType.HAS_AI,
          value: false,
        },
      ],
      subComponents: [],
    },
  ],
  createdBy: "user-1",
  createdAt: new Date(),
  updatedAt: new Date(),
};

export const mockHardwareDevice: Device = {
  id: "device-1",
  name: "Device 1",
  description: "Device 1 description",
  components: [
    {
      id: "component-1",
      name: "Component 1",
      description: "Component 1 description",
      type: ComponentType.HARDWARE,
      configuration: [],
      subComponents: [],
    },
  ],
  createdBy: "user-1",
  createdAt: new Date(),
  updatedAt: new Date(),
};

export const mockHardwareDeviceWithSterile: Device = {
  ...mockHardwareDevice,
  components: [
    ...mockHardwareDevice.components,
    {
      ...mockHardwareDevice.components[0],
      configuration: [
        {
          type: ComponentConfigurationType.IS_STERILIZATION_INTENDED,
          value: true,
        },
      ],
    },
  ],
};

const userId = "user-1";
const orgId = "1";

export const mockOrganization: Organization = {
  id: orgId,
  name: "Organization 1",
  createdAt: new Date(),
  updatedAt: new Date(),
  subscription: null,
};

const membership: OrganizationMemberWithOrganization = {
  id: "1",
  organizationId: orgId,
  homeOrg: true,
  createdAt: new Date(),
  updatedAt: new Date(),
  userId,
  organization: mockOrganization,
};

export const mockUser: User = {
  id: userId,
  authId: "auth-1",
  firstName: "User",
  lastName: "1",
  email: "user1@example.com",
  createdAt: new Date(),
  updatedAt: new Date(),
  organizationMemberships: [membership],
  onboarding: {
    createdAt: new Date(),
    updatedAt: new Date(),
    callScheduled: false,
  },
};

export const mockDocumentVersion: DocumentVersion = {
  id: "123",
  answers: [],
  createdBy: userId,
  createdAt: new Date(),
  updatedAt: new Date(),
  revisionSummary: "",
  suggestions: [],
  documentId: "123",
  readyForApproval: false,
  documentVersionApprover: [],
};

export const mockDocument: Document = {
  id: "123",
  name: "Document 1",
  createdBy: userId,
  createdAt: new Date(),
  updatedAt: new Date(),
  deviceId: "device-1",
  versions: [mockDocumentVersion],
};

export const mockDocumentAnswer: DocumentAnswer = {
  element: "expected-lifetime",
  answer: "correct",
  id: "123",
  createdAt: new Date(),
  documentVersionId: "123",
  createdBy: userId,
};

export const mockEntitlement: Entitlement = {
  id: "ent-1",
  type: EntitlementType.DEVICE_LIMIT,
  value_int: -1,
};

export const mockProduct: Product = {
  id: "prod-1",
  name: "Premium Flat",
  stripeProductId: window._env_.PREMIUM_STRIPE_PRODUCT_ID,
  entitlements: [mockEntitlement],
};

const subscriptionId = "sub-1";

export const mockSubscriptionItem: SubscriptionItem = {
  id: "item-1",
  productQuantity: 2,
  interval: BillingInterval.YEARLY,
  subscriptionId: subscriptionId,
  productId: mockProduct.id,
  product: mockProduct,
};

export const mockSubscription: Subscription = {
  id: subscriptionId,
  organizationId: mockOrganization.id,
  status: "active",
  stripeSubscriptionId: "stripe-sub-1",
  subscriptionItems: [mockSubscriptionItem],
};
export const mockDocuments: Document[] = [
  {
    id: "doc-1",
    name: TEMPLATE_TYPE.CLINICAL_EVALUATION_REPORT,
    deviceId: "device-1",
    createdBy: "user-1",
    createdAt: new Date(),
    updatedAt: new Date(),
    versions: [mockDocumentVersion],
  },
  {
    id: "doc-2",
    name: TEMPLATE_TYPE.BUG_FIX_REPORT,
    deviceId: "device-1",
    createdBy: "user-1",
    createdAt: new Date(),
    updatedAt: new Date(),
    versions: [mockDocumentVersion],
  },
];
