import {
  useAnswerHistory,
  useDocEngine,
  usePatchDocumentVersionMutation,
  useSaveAnswerMutation,
  useSaveSuggestion,
} from "@hooks";
import { ChevronRight } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Button, Typography } from "@mui/material";
import { hasInlineSuggestion } from "@utils";
import Handlebars from "handlebars";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { isStepComplete } from "src/utils/step";
import {
  Device,
  Document,
  DocumentAnswer,
  DocumentVersion,
  TEMPLATE_TYPE,
} from "../../stores/models";
import {
  StepValue,
  TemplateElement,
  Suggestion as TSuggestion,
} from "../../types";
import { ElementMapper } from "./ElementMapper";
import { HelpBox } from "./HelpBox";
import { Suggestion } from "./Suggestion";
import {
  generateSuggestion,
  getAnswer,
  getAnswerFromDocumentVersion,
  getLatestSavedSuggestion,
  runTransformer,
} from "./utils";

type Props = {
  templateId: TEMPLATE_TYPE;
  activeStep: TemplateElement;
  steps: TemplateElement[];
  step: TemplateElement;
  containerRef: React.RefObject<HTMLDivElement>;
  device: Device;
  documentVersion: DocumentVersion;
  documents: Document[];
};

export const Step = ({
  templateId,
  activeStep,
  device,
  documents,
  documentVersion,
  step,
  steps,
}: Props) => {
  const {
    orgId = "",
    deviceId = "",
    docId = "",
  } = useParams<{
    orgId: string;
    deviceId: string;
    templateId: TEMPLATE_TYPE;
    docId?: string;
  }>();

  let [_, setSearchParams] = useSearchParams();

  const isVisible = activeStep.id === step.id;
  const isLastStep = activeStep.id === steps[steps.length - 1].id;

  const saveSuggestion = useSaveSuggestion();
  const saveAnswerMutation = useSaveAnswerMutation();
  const patchDocumentMutation = usePatchDocumentVersionMutation();

  const docEngine = useDocEngine(deviceId);

  const [isGenerating, setIsGenerating] = useState(false);
  const [stepValue, setStepValue] = useState<StepValue>(
    getAnswer(
      templateId,
      { answer: undefined, answerFileId: undefined },
      step,
      documentVersion,
      documents
    )
  );
  const [suggestion, setSuggestion] = useState<TSuggestion>({
    applied: false,
    value: getLatestSavedSuggestion(step, documentVersion) || "",
    completed: false,
    saved: false,
    loading: false,
    error: false,
    isStatusMessage: false,
    statusMessageType: undefined,
  });

  const handleForceRunTransformer = () => {
    const newAnswer = runTransformer(
      templateId,
      step,
      documentVersion,
      documents
    );
    setStepValue({ answer: newAnswer, answerFileId: undefined });
  };

  const nextStep = (
    step: TemplateElement,
    answer: StepValue,
    documentVersion: DocumentVersion,
    steps: TemplateElement[]
  ) => {
    const nextStep = steps[steps.findIndex((s) => s.id === step.id) + 1];
    setSearchParams((params) => ({ ...params, step: nextStep.id }));
    handleSaveAnswer(answer, step, documentVersion);
  };

  useEffect(() => {
    if (isVisible && !suggestion.loading && step.prompt) {
      if (
        // If is side-by-side suggestion/answer
        (!hasInlineSuggestion(step) && !suggestion.value) ||
        // If is inline suggestion/answer
        (hasInlineSuggestion(step) && !stepValue.answer)
      ) {
        handleGenerateSuggestion(device, documents, documentVersion, step);
      }
    }
  }, [suggestion, step.prompt, isVisible]);

  const { element } = step;
  const [__, updateHistory, undo, redo] = useAnswerHistory({
    elementId: step.id,
  });

  const handleChange = (_: TemplateElement, value: StepValue) => {
    setStepValue(value);
  };

  const handleUndo = () => {
    const newAnswer = undo();
    if (newAnswer) {
      handleChange(step, { answer: newAnswer });
    }
  };

  const handleRedo = () => {
    const newAnswer = redo();
    if (newAnswer) {
      handleChange(step, { answer: newAnswer });
    }
  };

  const [debouncedAnswer, setDebouncedAnswer] = useState<StepValue>();

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedAnswer(stepValue);
    }, 3000);

    return () => clearTimeout(timer);
  }, [stepValue]);

  useEffect(() => {
    if (typeof debouncedAnswer?.answer === "string") {
      handleSaveAnswer(debouncedAnswer, step, documentVersion, true);
    }
  }, [debouncedAnswer]);

  const isFetchingSuggestion = suggestion.loading;

  useEffect(() => {
    if (typeof stepValue.answer === "string") {
      updateHistory(stepValue.answer);
    }
  }, [stepValue]);

  if (!!element.options.helperText) {
    // Extract all data keys and their answers from all the latest versions of the documents
    const dataKeys = documents.reduce(
      (acc, doc) => ({
        ...acc,
        ...doc.versions[0]?.answers.reduce(
          (answersAcc, answer) => ({
            ...answersAcc,
            [answer.element]: answer.answer,
          }),
          {}
        ),
      }),
      {}
    );

    const helperText = element.options.helperText
      .split("\n")
      .map((line) => line.trim())
      .join("\n");
    // Use Handlebars to replace variables in the helper text
    const template = Handlebars.compile(helperText);
    element.options.helperText = template(dataKeys);
  }

  const handleNextStep = () => {
    nextStep(step, stepValue, documentVersion, steps);
  };

  const isNextStepDisabled =
    !isStepComplete(step, stepValue) || isGenerating || isFetchingSuggestion;

  const handleApplySuggestion = (step: TemplateElement, suggestion: string) => {
    setSuggestion((prevSuggestion) => ({
      ...prevSuggestion,
      applied: true,
    }));

    handleChange(step, { answer: suggestion });
  };

  const handleGenerateSuggestion = async (
    device: Device,
    documents: Document[],
    documentVersion: DocumentVersion,
    step: TemplateElement
  ) => {
    // Set the loading state for the suggestion to true
    setSuggestion((prevSuggestions) => ({
      ...prevSuggestions,
      value: "",
      saved: false,
      applied: false,
      completed: false,
      isStatusMessage: false,
      statusMessageType: undefined,
      loading: true,
      error: false,
    }));

    if (hasInlineSuggestion(step)) {
      setStepValue({ answer: "", answerFileId: undefined });
    }

    // Start fetching the suggestion
    const generator = generateSuggestion(
      templateId,
      step.id,
      device,
      documents,
      documentVersion
    );

    if (generator === undefined) return;

    let suggestion = "";

    try {
      let statusMessage: { status: string; message: string } | undefined =
        undefined;
      let previousWasStatusMessage = false;

      for await (const value of generator) {
        // check if the token is a status message object with the structure {status: "info", message: "some message"}

        if (value.startsWith("{") && value.endsWith("}")) {
          try {
            const event = JSON.parse(value);
            if (event.status && event.message) {
              statusMessage = event;
              previousWasStatusMessage = true;
            }
          } catch (error) {
            console.error(error);
          }
        } else {
          statusMessage = undefined;
        }

        if (statusMessage) {
          suggestion = statusMessage.message;
        } else if (!statusMessage && previousWasStatusMessage) {
          suggestion = value;
          previousWasStatusMessage = false;
        } else {
          // TODO find out why this instead of previousState + value resolves the issue with the double backslashes
          suggestion += value;
        }

        setSuggestion((prevSuggestion) => ({
          ...prevSuggestion,
          value: suggestion,
          loading: true,
          completed: false,
          isStatusMessage: !!statusMessage,
          statusMessageType: statusMessage?.status as "error" | "info",
        }));

        if (hasInlineSuggestion(step)) {
          setStepValue({ answer: suggestion, answerFileId: undefined });
        }
      }

      saveSuggestion.mutate({
        orgId,
        suggestion: suggestion,
        documentVersion,
        device,
        docId,
        step,
      });

      // If the suggestion is inline, save the answer as well as the newest suggestion is always also the answer
      if (hasInlineSuggestion(step)) {
        setStepValue({ answer: suggestion, answerFileId: undefined });
        saveAnswerMutation.mutate({
          orgId,
          answer: suggestion,
          step: step,
          documentVersion,
          docId,
          deviceId,
        });
      }

      // Once the generator finishes, set the loading state to false and the completed state to true
      setSuggestion((prevSuggestions) => ({
        ...prevSuggestions,
        completed: true,
        loading: false,
        saved: true,
      }));
    } catch (error) {
      // Handle the error here
      console.error("Error in generator:", error);
      setSuggestion((prevSuggestion) => ({
        ...prevSuggestion,
        loading: false,
        completed: true,
        error: true,
      }));
    }
  };

  const handleFinalStep = async (
    step: TemplateElement,
    answer: StepValue,
    docVersion: DocumentVersion,
    device: Device,
    documents: Document[]
  ) => {
    setIsGenerating(true);

    // Set the status of the document version to completed for the local state to reflect the change and make the is completed check work
    docVersion.readyForApproval = true;

    // Save the answer for the last step
    const savedAnswer = await handleSaveAnswer(answer, step, docVersion);

    // TODO move this to onMutate or onSuccess of the saveAnswerMutation
    if (savedAnswer) {
      // Replace the answer in the document version with the saved answer
      docVersion.answers = docVersion.answers.map((a) =>
        a.element === step.id ? savedAnswer : a
      );

      // Or add the answer if it does not exist yet in the document version add the answer
      if (!docVersion.answers.find((a) => a.element === step.id)) {
        docVersion.answers.push(savedAnswer);
      }
    }

    //   Update the document version status to completed
    await patchDocumentMutation.mutateAsync({
      orgId,
      docId: docId,
      deviceId: deviceId,
      docVersionId: docVersion.id,
      data: { readyForApproval: true },
    });

    await docEngine.navigateToWizardOrQMS({
      templateId,
      documentId: docId,
      device,
      documents,
    });
  };

  const handleSaveAnswer = (
    newStepValue: StepValue,
    step: TemplateElement,
    documentVersion: DocumentVersion,
    isAutoSave?: boolean
  ): Promise<DocumentAnswer | undefined> => {
    const savedAnswer = getAnswerFromDocumentVersion(
      step.element,
      documentVersion
    );

    const { answerFileId, answer } = newStepValue;

    // We do not want to automatically save the default value or if the answer is the same as the current saved answer
    if (
      typeof answer === "string" &&
      isAutoSave &&
      answer === step.element.options.default
    ) {
      return Promise.resolve(undefined);
    }

    const hasFileIdChanged = answerFileId !== savedAnswer?.answerFileId;
    const hasAnswerChanged = answer !== savedAnswer?.answer;

    if (typeof answerFileId === "string" && hasFileIdChanged) {
      return saveAnswerMutation.mutateAsync({
        orgId,
        step: step,
        documentVersion,
        deviceId,
        docId,
        answerFileId,
      });
    }

    if (typeof answer === "string" && hasAnswerChanged) {
      return saveAnswerMutation.mutateAsync({
        orgId,
        step: step,
        documentVersion,
        deviceId,
        docId,
        answer,
      });
    } else {
      return Promise.resolve(undefined);
    }
  };

  if (!isVisible) {
    return null;
  }

  return (
    // replacing div with slider for now since it caused performance issues
    <div
      key={step.id}
      className={`${isVisible ? "w-full" : "hidden"} flex flex-1 flex-col gap-y-2`}
    >
      <div className="flex flex-col gap-y-3">
        <Typography variant="h1">{element.options.label}</Typography>
        {(!!element.options.helperText || step.videoUrl) && (
          <HelpBox step={step} />
        )}
      </div>
      <div className="flex w-full flex-1 flex-row items-start gap-4">
        {!hasInlineSuggestion(step) && step.prompt && (
          <Suggestion
            suggestion={suggestion}
            step={step}
            handleApply={(_, suggestion) =>
              handleApplySuggestion(step, suggestion)
            }
            device={device}
            onRegenerateSuggestion={() =>
              handleGenerateSuggestion(device, documents, documentVersion, step)
            }
          />
        )}

        <div
          className={`flex ${
            hasInlineSuggestion(step) ? "w-full" : "w-1/2"
          } h-full flex-1 flex-col gap-y-4`}
        >
          <ElementMapper
            step={step}
            suggestion={suggestion}
            onChange={handleChange}
            hasInlineSuggestion={hasInlineSuggestion(step)}
            value={stepValue}
            onRegenerateSuggestion={() =>
              handleGenerateSuggestion(device, documents, documentVersion, step)
            }
            onReRunTransformer={handleForceRunTransformer}
            onApplySuggestion={(_, suggestion) =>
              handleApplySuggestion(step, suggestion)
            }
            onRedo={handleRedo}
            onUndo={handleUndo}
          />
          <div className="self-end">
            {!isLastStep && (
              <Button
                onClick={handleNextStep}
                disabled={isNextStepDisabled}
                variant="contained"
                endIcon={<ChevronRight />}
                //className="px-4 py-2 bg-blue-300 disabled:opacity-50"
              >
                Next
              </Button>
            )}
            {/* If last step, show generate button */}
            {isLastStep && (
              <LoadingButton
                variant="contained"
                loading={isGenerating}
                disabled={isNextStepDisabled}
                onClick={() =>
                  handleFinalStep(
                    step,
                    stepValue,
                    documentVersion,
                    device,
                    documents
                  )
                }
              >
                Generate
              </LoadingButton>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
