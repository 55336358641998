import { useDocEngine, useGetDevice } from "@hooks";
import { Document, TEMPLATE_TYPE } from "@models";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { templatesWithUpload } from "src/config";
import { DocProgress } from "../../types";
import { DocumentViewerSkeleton } from "../Skeletons";
import { GeneratedDocViewer } from "./components/GeneratedDocViewer";
import NoDocAnimation from "./components/NoDocAnimation";
import { FileDocViewer } from "./components/fileDocViewer/FileDocViewer";

const DocViewer = ({
  documents,
  deviceId,
  templateId,
  docProgress,
  isLoading,
}: {
  documents: Document[] | undefined;
  deviceId: string;
  templateId: TEMPLATE_TYPE | undefined;
  docProgress: DocProgress | undefined;
  isLoading: boolean;
}) => {
  const docEngine = useDocEngine(deviceId);

  const { orgId = "" } = useParams();
  const { data: device } = useGetDevice({ orgId, deviceId });

  const [isButtonActionLoading, setIsButtonActionLoading] = useState(false);

  if (templateId && templatesWithUpload.includes(templateId)) {
    return (
      <FileDocViewer
        deviceId={deviceId}
        templateId={templateId}
        key={templateId}
      />
    );
  }

  if (isLoading || !documents || !device) {
    return <DocumentViewerSkeleton />;
  }

  if (!templateId || !docProgress) return <NoDocAnimation type="NoDoc" />;

  if (docProgress?.enabled === false) {
    <NoDocAnimation type="LockedDoc" />;
  }

  if (docProgress?.noInstance === true) {
    return (
      <NoDocAnimation
        type="NoInstance"
        isLoading={isButtonActionLoading}
        buttonOnClick={async () => {
          setIsButtonActionLoading(true);
          await docEngine.navigateToWizardOrQMS({
            templateId,
            device,
            documents,
          });
          setIsButtonActionLoading(false);
        }}
      />
    );
  }

  return <GeneratedDocViewer deviceId={deviceId} templateId={templateId} />;
};

export default DocViewer;
