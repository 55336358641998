import {
  Device,
  Document,
  RoadmapTasks as RoadmapTasksEntity,
  deviceSchema,
  roadmapTasksSchema,
} from "@models";
import { DataKeyWithValue } from "@types";
import { getAvailableDataKeys } from "@utils";
import { forEach } from "lodash";
import { makeAutoObservable } from "mobx";
import { RootStoreType } from ".";
import {
  ROADMAP_CARDS,
  ROADMAP_STAGES,
  ROADMAP_TASKS,
  RoadmapAsArray,
  RoadmapAsMap,
  RoadmapCardId,
  RoadmapCardWithTaskMap,
  RoadmapConfig,
  RoadmapTask,
  RoadmapTaskId,
} from "../config";
import { deleteDevice, getRoadmapTasks, patchDevice } from "../services";

export class DeviceStore {
  // Todo
  _roadmaps: Map<string, RoadmapAsMap> = new Map();

  rootStore;

  constructor(rootStore: RootStoreType) {
    makeAutoObservable(this, { rootStore: false }, { autoBind: true });
    this.rootStore = rootStore;
  }

  async deleteDevice(orgId: string, id: string) {
    await deleteDevice(orgId, id);
  }

  async updateDevice(orgId: string, device: Device) {
    const { data } = await patchDevice(orgId, device);
    return await deviceSchema.validate(data);
  }

  async fetchRoadmapTasks(
    orgId: string,
    deviceId: string
  ): Promise<RoadmapTasksEntity> {
    const { data } = await getRoadmapTasks(orgId, deviceId);
    return await roadmapTasksSchema.validate(data);
  }

  mapRoadmap(
    tasks: Record<string, boolean>,
    device: Device,
    documents: Document[]
  ) {
    const roadmap: RoadmapAsMap = new Map();

    const availableDataKeys = getAvailableDataKeys(documents);

    const roadmapConfig = this.mapDeviceConfigurationToRoadmap(
      availableDataKeys,
      device
    );

    forEach(roadmapConfig, (phase) => {
      let cardMap: Map<RoadmapCardId, RoadmapCardWithTaskMap> = new Map();
      forEach(phase.cards, (card) => {
        let taskMap: Map<RoadmapTaskId, RoadmapTask> = new Map();
        forEach(card.tasks, (task) => {
          taskMap.set(task.id, {
            ...task,
            completed: tasks[task.id],
          });
        });
        let newCard = { ...card, tasks: taskMap };
        cardMap.set(card.id, newCard);
      });
      let newPhase = {
        ...phase,
        cards: cardMap,
      };
      roadmap.set(phase.id, newPhase);
    });

    return Array.from(roadmap.values()).map((phase) => ({
      ...phase,
      cards: Array.from(phase.cards.values()).map((card) => ({
        ...card,
        tasks: Array.from(card.tasks.values()),
      })),
    }));
  }

  getRoadmap = (deviceId: string): RoadmapAsArray | undefined => {
    const roadmap = this._roadmaps.get(deviceId);
    if (!roadmap) {
      return undefined;
    }

    return Array.from(roadmap.values()).map((phase) => ({
      ...phase,
      cards: Array.from(phase.cards.values()).map((card) => ({
        ...card,
        tasks: Array.from(card.tasks.values()),
      })),
    }));
  };

  replaceArrayObjectByKey(obj: any, key = "id", files = []) {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        if (Array.isArray(obj[prop])) {
          for (var i = 0; i < obj[prop].length; i++) {
            if (
              obj[prop][i].hasOwnProperty(key) &&
              !obj[prop][i].hasOwnProperty("files") &&
              !obj[prop][i].hasOwnProperty("address") &&
              !obj[prop][i].hasOwnProperty("model")
            ) {
              // @ts-ignore
              files.push(obj[prop][i]);
              obj[prop][i] = obj[prop][i][key];
              // console.log("CHANGED: ", obj[prop][i], " to ", obj[prop][i]);
            } else {
              this.replaceArrayObjectByKey(obj[prop][i], key, files);
            }
          }
        } else if (typeof obj[prop] === "object") {
          this.replaceArrayObjectByKey(obj[prop], key, files);
        }
      }
    }
    return { obj, files };
  }

  mapDeviceConfigurationToRoadmap = (
    availableDataKeys: DataKeyWithValue[],
    device: Device
  ): RoadmapConfig => {
    return ROADMAP_STAGES.map((stage) => ({
      ...stage,
      cards: ROADMAP_CARDS.filter((card) => {
        let visible = card.stage === stage.id;

        if (card.visibilityCondition && visible) {
          visible = card.visibilityCondition(availableDataKeys, device);
        }

        return visible;
      })
        .map((card) => ({
          ...card,
          tasks: ROADMAP_TASKS.filter((task) => task.card === card.id),
        }))
        .sort((a, b) => {
          a.order = a.order || 0;
          b.order = b.order || 0;

          return a.order - b.order;
        }),
    }));
  };

  mapTasks(deviceId: string) {
    const roadmap = this.getRoadmap(deviceId);
    if (!roadmap) {
      throw new Error("Roadmap not found");
    }

    return roadmap.reduce((tasks: Record<string, boolean>, phase) => {
      phase.cards.forEach((card) => {
        card.tasks.forEach((task) => {
          tasks[task.id] = task.completed;
        });
      });
      return tasks;
    }, {});
  }
}
