import { CheckIcon, UncheckIcon } from "@assets";
import { BillingInterval, Plan, User } from "@models";
import { LoadingButton } from "@mui/lab";
import { Chip, Divider, Typography } from "@mui/material";
import { getSubscription } from "@utils";
import { useParams } from "react-router-dom";
import { getOrgFromUser } from "src/utils/user";
import { PlanConfig } from "./plans";

export const PlanCard = ({
  plan,
  deviceCount,
  loadingPlan,
  getPrice,
  handleChoosePlan,
  selectedBillingCycle,
  user,
}: {
  plan: PlanConfig;
  deviceCount: number;
  loadingPlan: string | null;
  getPrice: (plan: { monthlyPrice: number; yearlyPrice: number }) => string;
  handleChoosePlan: (planId: Plan, deviceCount: number) => Promise<void>;
  selectedBillingCycle: BillingInterval;
  user: User;
}) => {
  const { orgId = "" } = useParams();
  const org = getOrgFromUser(user, orgId);
  if (!org) return null;
  const {
    products: activeProducts,
    billingInterval: activeBillingInterval,
    quantity: activeQuantity,
  } = getSubscription(org);

  const isCurrentDeviceCount = deviceCount === activeQuantity;
  const isCurrentBillingCycle = selectedBillingCycle === activeBillingInterval;

  const isCurrentPlan =
    plan.stripeId && activeProducts.includes(plan.stripeId) ? true : false;

  const hasSubscription = !!org.subscription;
  const hasFreePlan = plan.id === Plan.FREE && !hasSubscription;

  const isCurrentSubscription =
    (isCurrentDeviceCount && isCurrentBillingCycle && isCurrentPlan) ||
    hasFreePlan;

  const isPlanChange = hasSubscription && !isCurrentPlan;
  const isCycleChange =
    hasSubscription && selectedBillingCycle !== activeBillingInterval;

  const planButtonText = isCurrentSubscription
    ? "Current plan"
    : isPlanChange || isCycleChange
      ? "Contact Support"
      : "Choose plan";

  return (
    <div className="rounded-md border border-solid border-slate-300 p-4 max-w-sm">
      <Typography variant="h2">
        {plan.name}{" "}
        {plan.popular && (
          <Chip
            label="Recommended"
            className="bg-purple-100 text-purple-500 border-purple-500 border-solid border ml-2"
            size="tiny"
          />
        )}
      </Typography>
      <Typography variant="h4" component="div" className="mt-4">
        {getPrice(plan)}€
      </Typography>
      <Typography variant="subtitle1" component="span">
        Per device/month
      </Typography>
      <Typography variant="body1" color="text.secondary" className="mt-2">
        {plan.shortDescription}
      </Typography>
      <LoadingButton
        data-testid={`${plan.name.toLowerCase()}-button`}
        variant={isCurrentSubscription ? "outlined" : "contained"}
        color="primary"
        loading={loadingPlan === plan.id}
        fullWidth
        className={`mt-4 ${isCurrentSubscription ? "cursor-not-allowed" : ""}`}
        onClick={
          isCurrentSubscription
            ? undefined
            : () => handleChoosePlan(plan.id, deviceCount)
        }
        disableRipple={isCurrentSubscription}
      >
        {planButtonText}
      </LoadingButton>
      <Divider className="my-4" />
      <div className="flex flex-col gap-6">
        {plan.features.map((feature, index) => (
          <div key={index} className="flex items-center gap-3">
            <img
              src={
                typeof feature === "string" || feature.included
                  ? CheckIcon
                  : UncheckIcon
              }
              alt={
                typeof feature === "string" || feature.included
                  ? "Check"
                  : "Uncheck"
              }
            />
            <Typography variant="body1">
              {typeof feature === "string" ? feature : feature.text}
            </Typography>
          </div>
        ))}
      </div>
    </div>
  );
};
