import { Box, Menu, MenuItem } from "@mui/material";
import { Link } from "react-router-dom";

import { User } from "@models";
import { fillRouteWithSlugs } from "@utils";
import {
  getFirst2Initials,
  stringToColorHash,
} from "src/components/NavBar/utils";
import { ROUTES, theme } from "src/config";
import { ROUTE_SLUGS } from "src/config/navigation/routes";

type Props = {
  user: User;
  anchorOrgMenu: Element | null;
  isOrgMenuOpen: boolean;
  closeOrgMenu: () => void;
  closeUserMenu: () => void;
};

export const SwitchOrgMenu = ({
  user,
  anchorOrgMenu,
  isOrgMenuOpen,
  closeOrgMenu,
  closeUserMenu,
}: Props) => {
  if (!user) return null;

  return (
    <Menu
      id="org-menu"
      anchorEl={anchorOrgMenu}
      open={isOrgMenuOpen}
      onClose={closeOrgMenu}
      onClick={() => {
        closeOrgMenu();
      }}
      onMouseLeave={closeOrgMenu}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
      disablePortal
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      sx={{
        left: 10,
        top: -10,
        zIndex: 10,
      }}
    >
      <div onMouseLeave={closeOrgMenu}>
        {user.organizationMemberships.map((org) => (
          <MenuItem
            key={org.id}
            component={Link}
            to={fillRouteWithSlugs(ROUTES.DEVICE_MANAGEMENT, {
              [ROUTE_SLUGS.ORG_ID]: org.organizationId,
            })}
            sx={{
              "&:hover": { bgcolor: "rgba(0, 0, 0, 0.04)" },
            }}
            className="gap-3"
            onClick={closeUserMenu}
          >
            <Box
              className="w-6 h-6 rounded flex items-center justify-center text-xs font-medium"
              sx={{
                bgcolor:
                  (org.organization.id &&
                    stringToColorHash(org.organization.id)) ||
                  theme.palette.grey[200],
              }}
            >
              {org.organization.name
                ? getFirst2Initials(org.organization.name)
                : "?"}
            </Box>
            {org.organization.name || "Unknown Org"}
          </MenuItem>
        ))}
      </div>
    </Menu>
  );
};
