import { useSearchParams } from "react-router-dom";
import { ROUTE_SLUGS, ROUTES } from "src/config/navigation/routes";

/**
 * Hook to update search params of the current url without removing existing params
 * @param params the urls query params to set or update
 * @returns a function to update search params
 */
export const useUpdateSearchParams = () => {
  const [_, setSearchParams] = useSearchParams();

  const updateSearchParams = (params: { [i: string]: string }) => {
    setSearchParams((searchParams) => {
      Object.entries(params).forEach(([k, v]) => {
        searchParams.set(k, v);
      });
      return searchParams;
    });
  };

  return updateSearchParams;
};

export const fillRouteWithSlugs = (
  route: ROUTES,
  slugs: {
    [key in ROUTE_SLUGS]?: string;
  } = {}
) => {
  let fullRoute: string = route;
  Object.entries(slugs).forEach(([key, value]) => {
    fullRoute = fullRoute.replace(key, value);
  });
  return fullRoute;
};
