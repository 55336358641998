import { ALWAYS_AVAILABLE_CARDS } from "@config";
import {
  BillingInterval,
  Entitlement,
  EntitlementType,
  Organization,
  Plan,
  SimpleSubscription,
} from "@models";
import { ROADMAP_TASKS } from "src/config";

export const isPaidDeviceCreation = (
  org: Organization,
  currentDeviceCount: number
) => {
  const deviceLimit = getActiveDeviceLimit(org);
  if (deviceLimit === -1) {
    return false;
  }
  return currentDeviceCount + 1 > deviceLimit;
};

/**
 * @returns all entitlements for the user's organization
 */
const getEntitlements = (org: Organization): Entitlement[] => {
  return (
    org?.subscription?.subscriptionItems.reduce((acc, item) => {
      return [...acc, ...(item.product.entitlements ?? [])];
    }, [] as Entitlement[]) || []
  );
};

const getActiveDeviceLimit = (org: Organization): number => {
  const products = getProducts(org);
  if (products.includes(Plan.FREE)) {
    return 1;
  }

  const entitlements = getEntitlements(org);

  const deviceLimit = entitlements.find(
    (entitlement) => entitlement.type === EntitlementType.DEVICE_LIMIT
  );

  if (deviceLimit?.value_int === -1) {
    return -1;
  }
  return deviceLimit?.value_int ?? 1 * getPurchasedDeviceQuantity(org) ?? 1;
};

/**
 *
 * @returns the number of devices the user's organization has purchased
 */
const getPurchasedDeviceQuantity = (org: Organization): number => {
  const products = getProducts(org);
  if (products.includes(Plan.FREE)) {
    return 1;
  } else {
    return (
      org.subscription?.subscriptionItems.find((item) =>
        item.product.entitlements?.find(
          (entitlement) => entitlement.type === EntitlementType.DEVICE_LIMIT
        )
      )?.productQuantity ?? 1
    );
  }
};

const getBillingInterval = (org: Organization): BillingInterval => {
  const products = getProducts(org);
  if (products.includes(Plan.FREE)) {
    return BillingInterval.YEARLY;
  }

  return (
    org.subscription?.subscriptionItems[0].interval ?? BillingInterval.YEARLY
  );
};

/**
 * @returns the subscription plan for the user's organization.
 * If the user's organization does not have a subscription, returns Plan.FREE.
 */
const getProducts = (org: Organization): string[] => {
  if (!org.subscription) {
    return [Plan.FREE];
  } else {
    return org.subscription.subscriptionItems.map(
      (item) => item.product.stripeProductId
    );
  }
};

/**
 * @returns the subscription plan, billing interval, and quantity for the user's organization
 */
export const getSubscription = (org: Organization): SimpleSubscription => {
  const products = getProducts(org);
  const billingInterval = getBillingInterval(org);
  const quantity = getPurchasedDeviceQuantity(org);
  const entitlements = getEntitlements(org);
  return { products, billingInterval, quantity, entitlements };
};

export const isUserEntitledToDoc = (org: Organization, templateId: string) => {
  const entitlements = getEntitlements(org);

  if (
    entitlements.find(
      (entitlement) =>
        entitlement.type === EntitlementType.ALL_DOCUMENTS_UNLOCKED
    )
  ) {
    return true;
  }

  return Boolean(
    ROADMAP_TASKS.find(
      (task) =>
        task.id === templateId && ALWAYS_AVAILABLE_CARDS.includes(task.card)
    )
  );
};
