import { TemplateAssistant, TemplateElement } from "@types";

export const findMatchingElements = (
  template: TemplateAssistant,
  searchQuery: string
): TemplateElement[] => {
  if (!searchQuery) return [];

  const normalizedQuery = searchQuery.toLowerCase().split(" ");

  return template.elements.filter((element) => {
    if ("id" in element) {
      const label = element.id.toLowerCase();
      return normalizedQuery.every((query) => label.includes(query));
    }
    return false;
  });
};

export const HighlightedText = ({
  text,
  highlight,
}: {
  text: string;
  highlight: string;
}) => {
  if (!highlight.trim()) {
    return <span>{text}</span>;
  }

  const parts = text.split(new RegExp(`(${highlight})`, "gi"));

  return (
    <span>
      {parts.map((part, index) =>
        part.toLowerCase() === highlight.toLowerCase() ? (
          <span key={index} style={{ backgroundColor: "#fff3b0" }}>
            {part}
          </span>
        ) : (
          <span key={index}>{part}</span>
        )
      )}
    </span>
  );
};
