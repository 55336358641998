import { TEMPLATE_TYPE } from "@models";
import { DocProgress, DocProgressMap } from "@types";
import { useParams } from "react-router-dom";
import { ASSISTANT_CONFIG } from "src/config";
import { useGetDevice, useGetDocuments } from "src/hooks/queries";
import {
  isDocumentCompleted,
  isDocumentHidden,
  isDocumentUnlocked,
} from "src/utils";

/**
 * Get the progress/status of all the documents.
 * Maps from templateId to DocProgress (which currently overwrites multiinstance documents)
 */
export const useDocProgressMap = (deviceId: string): DocProgressMap => {
  const { orgId = "" } = useParams();
  const { data: documents } = useGetDocuments(orgId, deviceId);
  const { data: device } = useGetDevice({ orgId, deviceId });
  const newDocProgressMap: Record<string, DocProgress> = {};

  if (!documents || !device) {
    return {};
  }
  documents.forEach((doc) => {
    const docTemplate = doc.name as TEMPLATE_TYPE;
    const docEnabledStatus = isDocumentUnlocked(docTemplate, documents);

    //TODO: change to doc.id and then we can fix the GeneratedDocViewer to only use this state
    newDocProgressMap[docTemplate] = {
      docTemplate,
      docId: doc.id,
      functionalGroup: ASSISTANT_CONFIG[docTemplate].functionalGroup,
      complete: isDocumentCompleted(doc, device, documents, doc.versions[0]),
      hidden: isDocumentHidden(docTemplate, documents, device),
      ...docEnabledStatus,
    };
  });

  return newDocProgressMap;
};
