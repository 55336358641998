import { useGetUser } from "@hooks";
import { CloseOutlined, SearchOutlined } from "@mui/icons-material";
import { IconButton, InputBase, Tooltip } from "@mui/material";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import { docsFilteredBySearch, getFunctionalGroupsToDocsMap } from "@utils";
import { useEffect, useRef, useState } from "react";
import { HighlightedText } from "src/components/DocExplorer/utils";
import { useKeyboardShortcuts } from "src/hooks/useKeyboardShortcuts";
import { ASSISTANT_CONFIG } from "../../config";
import { FunctionalGroupNames } from "../../config/assistant";
import { Document, User } from "../../stores/models";
import { TEMPLATE_TYPE } from "../../stores/models/template-document";
import { FunctionalGroup } from "../../types";
import TreeViewSkeleton from "./TreeViewSkeleton";
import DocExplorerLeaf from "./components/DocExplorerLeaf";

const generateTree = (
  deviceId: string,
  filteredDocs: Document[],
  allDocuments: Document[],
  user: User,
  searchTerm: string
) => {
  const functionalGroupsToDocsMap = getFunctionalGroupsToDocsMap(filteredDocs);

  return Object.entries(functionalGroupsToDocsMap)
    .sort(([groupA], [groupB]) => groupA.localeCompare(groupB))
    .map(([functionalGroup, docs]) => {
      return (
        <TreeItem
          key={functionalGroup}
          itemId={functionalGroup}
          label={FunctionalGroupNames[functionalGroup as FunctionalGroup]}
        >
          {docs
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((document, index) => {
              const docTemplateId = document.name as TEMPLATE_TYPE;

              return (
                <DocExplorerLeaf
                  documents={allDocuments}
                  template={ASSISTANT_CONFIG[docTemplateId]}
                  {...(searchTerm && {
                    highlightedText: (
                      <HighlightedText
                        text={ASSISTANT_CONFIG[docTemplateId].docName}
                        highlight={searchTerm}
                      />
                    ),
                  })}
                  deviceId={deviceId}
                  key={`${document.name}-${index}-link`}
                  user={user}
                />
              );
            })}
        </TreeItem>
      );
    });
};

export const DocExplorer = ({
  deviceId,
  templateId,
  className = "",
  documents,
  isLoading = false,
}: {
  deviceId: string;
  templateId?: TEMPLATE_TYPE;
  className?: string;
  documents: Document[] | undefined;
  isLoading?: boolean;
}) => {
  const { data: user } = useGetUser();
  const [search, setSearch] = useState("");
  const [expandedItems, setExpandedItems] = useState<string[]>(
    templateId ? [ASSISTANT_CONFIG[templateId].functionalGroup] : []
  );
  const [isSearchFocused, setIsSearchFocused] = useState(false);

  // Reference to the search input
  const searchInputRef = useRef<HTMLInputElement | null>(null);

  useKeyboardShortcuts({
    Escape: () => {
      setSearch("");
      searchInputRef.current?.blur();
    },
    "shift+/": () => {
      if (search === "") {
        searchInputRef.current?.focus();
      }
    },
  });

  const filteredBySearchDocs = docsFilteredBySearch(documents, search);

  useEffect(() => {
    if (!documents) return;

    if (!search.trim()) {
      setExpandedItems(
        templateId ? [ASSISTANT_CONFIG[templateId].functionalGroup] : []
      );
      return;
    }

    const matchingGroups = filteredBySearchDocs.map(
      (doc) => ASSISTANT_CONFIG[doc.name as TEMPLATE_TYPE].functionalGroup
    );

    setExpandedItems([...new Set(matchingGroups)]);
  }, [search, documents]);

  const showLoadingSkeleton = isLoading;

  const showEmptyState = !isLoading && documents && documents.length === 0;

  const isSearchActive = isSearchFocused || search;

  return (
    <div
      className={`min-h-[30rem] min-w-[16rem] overflow-auto rounded border border-solid border-slate-300 ${className}`}
    >
      <div className="flex items-center gap-2 border-0 border-b border-solid border-slate-300 p-2 pl-3">
        <InputBase
          sx={{ flex: 1 }}
          placeholder={"Search documents..."}
          value={isSearchActive ? search : "Documents explorer"}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          onFocus={() => setIsSearchFocused(true)}
          onBlur={() => setIsSearchFocused(false)}
          inputProps={{ "aria-label": "search documents" }}
          inputRef={searchInputRef}
        />

        <Tooltip
          title={
            isSearchActive ? (
              <>
                Clear search <kbd>Esc</kbd>
              </>
            ) : (
              <>
                Start search <kbd>/</kbd>
              </>
            )
          }
        >
          <IconButton
            type="button"
            aria-label={isSearchActive ? "clear search" : "search"}
            size="small"
            onClick={() => {
              if (isSearchActive) {
                setSearch("");
                searchInputRef.current?.blur();
              } else {
                searchInputRef.current?.focus();
              }
            }}
          >
            {isSearchActive ? (
              <CloseOutlined color="inherit" />
            ) : (
              <SearchOutlined color="inherit" />
            )}
          </IconButton>
        </Tooltip>
      </div>
      <div className="py-2">
        {showLoadingSkeleton && <TreeViewSkeleton />}

        {showEmptyState && (
          <div className="p-4 text-center text-gray-500">
            You don't have any documents yet.
          </div>
        )}

        {!showLoadingSkeleton && !showEmptyState && user && (
          <SimpleTreeView
            aria-label="file system navigator"
            expandedItems={expandedItems}
            onExpandedItemsChange={(_, ids) => setExpandedItems(ids)}
            selectedItems={templateId || ""}
          >
            {generateTree(
              deviceId,
              filteredBySearchDocs,
              documents || [],
              user,
              search
            )}
          </SimpleTreeView>
        )}
      </div>
    </div>
  );
};
