import {
  BillingInterval,
  Device,
  Document,
  DocumentAnswer,
  DocumentAnswerSchema,
  DocumentAnswerSuggestionSchema,
  DocumentSchema,
  DocumentVersion,
  DocumentVersionApprover,
  DocumentVersionSchema,
  FileSchema,
  Plan,
  RoadmapTasks,
  TEMPLATE_TYPE,
  User,
  deviceSchema,
} from "@models";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { fillRouteWithSlugs } from "@utils";
import { orderBy } from "lodash";
import { useNavigate } from "react-router-dom";
import { ROUTE_SLUGS } from "src/config/navigation/routes";
import {
  ASSISTANT_CONFIG,
  ROUTES,
  getApproversQueryKey,
  getCreateDocumentMutationKey,
  getDevicesQueryKey,
  getDocumentQueryKey,
  getDocumentsQueryKey,
  getOrganizationUsersQueryKey,
  getPatchApproverStatusMutationKey,
  getTasksQueryKey,
  getUserQueryKey,
  getUserTodosQueryKey,
} from "../config";
import {
  checkout,
  createDocument,
  createDocumentVersion,
  deleteDocumentApprover,
  patchApproverStatus,
  patchDocumentVersion,
  patchRoadmapTasks,
  patchUserInOrg,
  postBillingSession,
  postDevice,
  postDocumentAnswer,
  postDocumentAnswerSuggestion,
  postDocumentApprover,
  postUserInvite,
  updateOrg,
  uploadFile,
} from "../services";
import { NewDevice, PatchDocumentVersionData, TemplateElement } from "../types";

export const useSaveSuggestion = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      orgId,
      suggestion,
      documentVersion,
      step,
      device,
      docId,
    }: {
      orgId: string;
      suggestion: string;
      documentVersion: DocumentVersion;
      device: Device;
      docId: string;
      step: TemplateElement;
    }) => {
      const { data } = await postDocumentAnswerSuggestion(
        orgId,
        device.id,
        docId,
        documentVersion.id,
        {
          suggestion,
          element: step.id,
        }
      );
      return await DocumentAnswerSuggestionSchema.validate(data);
    },
    onSuccess: (_, { device, docId }) => {
      queryClient.invalidateQueries({
        queryKey: getDocumentQueryKey(device.id, docId),
      });
    },
  });
};

export const usePatchDocumentVersionMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      orgId,
      docId,
      docVersionId,
      deviceId,
      data,
    }: {
      orgId: string;
      docId: string;
      docVersionId: string;
      deviceId: string;
      data: PatchDocumentVersionData;
    }) => {
      const { data: newDoc } = await patchDocumentVersion(
        orgId,
        deviceId,
        docId,
        docVersionId,
        data
      );
      return DocumentVersionSchema.validateSync(newDoc);
    },
    onMutate: async ({ docId, docVersionId, deviceId, data }) => {
      const previousDoc: Document | undefined = queryClient.getQueryData(
        getDocumentQueryKey(deviceId, docId)
      );

      const previousDocs: Document[] | undefined = queryClient.getQueryData(
        getDocumentsQueryKey(deviceId)
      );

      if (!previousDoc || !previousDocs) {
        return;
      }

      const documentVersion = previousDoc.versions.find(
        (v) => v.id === docVersionId
      );

      if (!documentVersion) {
        return;
      }

      const updatedVersion = {
        ...documentVersion,
        ...data,
      };

      const updatedDoc = {
        ...previousDoc,
        versions: [
          updatedVersion,
          ...previousDoc.versions.filter((v) => v.id !== docVersionId),
        ],
      };

      queryClient.setQueryData(
        getDocumentQueryKey(deviceId, docId),
        updatedDoc
      );

      // Update the version status in the documents list local cache
      queryClient.setQueryData(getDocumentsQueryKey(deviceId), () => [
        ...previousDocs.filter((d) => d.id !== docId),
        updatedDoc,
      ]);

      return { previousDoc: previousDoc, previousDocs: previousDocs };
    },
    onError: (_, __, context) => {
      if (context?.previousDoc) {
        queryClient.setQueryData(
          getDocumentQueryKey(
            context.previousDoc.deviceId,
            context.previousDoc.id
          ),
          context.previousDoc
        );

        queryClient.setQueryData(
          getDocumentsQueryKey(context.previousDoc.deviceId),
          context.previousDocs
        );
      }
    },
    onSuccess: (_, { deviceId, docId }) =>
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: getUserTodosQueryKey(deviceId),
        }),
        queryClient.invalidateQueries({
          queryKey: getDocumentsQueryKey(deviceId),
        }),
        queryClient.invalidateQueries({
          queryKey: getDocumentQueryKey(deviceId, docId),
        }),
      ]),
  });
};

export const useCreateDeviceMutation = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation({
    mutationFn: async ({
      device,
      orgId,
    }: {
      device: NewDevice;
      orgId: string;
    }) => {
      const { data } = await postDevice(orgId, device);
      return await deviceSchema.validate(data);
    },
    onSuccess: (data, { orgId }) => {
      queryClient.setQueryData(getDevicesQueryKey(orgId), (old: Device[]) => [
        ...old,
        data,
      ]);
      navigate(
        fillRouteWithSlugs(ROUTES.DEVICE_OVERVIEW, {
          [ROUTE_SLUGS.DEVICE_ID]: data.id,
          [ROUTE_SLUGS.ORG_ID]: orgId,
        })
      );
    },
  });
};

export const useTaskFinishedMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      completed,
      tasks,
      templateId,
      deviceId,
      orgId,
    }: {
      completed: boolean;
      tasks: RoadmapTasks;
      templateId: TEMPLATE_TYPE;
      deviceId: string;
      orgId: string;
    }) => {
      return patchRoadmapTasks(orgId, deviceId, {
        ...tasks.tasks,
        [templateId]: completed,
      });
    },
    onMutate: ({ completed, deviceId, templateId }) => {
      queryClient.setQueryData(
        getTasksQueryKey(deviceId),
        (old: RoadmapTasks) => ({
          ...old,
          tasks: { ...old?.tasks, [templateId]: completed },
        })
      );
    },
    onSuccess: (_, { deviceId }) => {
      queryClient.invalidateQueries({ queryKey: getTasksQueryKey(deviceId) });
    },
  });
};

export const useCreateDocumentMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: getCreateDocumentMutationKey(),
    mutationFn: async ({
      orgId,
      templateId,
      deviceId,
    }: {
      orgId: string;
      templateId: TEMPLATE_TYPE;
      deviceId: string;
    }) => {
      const config = ASSISTANT_CONFIG[templateId];
      if (!config) {
        throw new Error("Invalid templateId");
      }
      const { data } = await createDocument(orgId, deviceId, templateId);
      return DocumentSchema.validate(data);
    },
    onSuccess: async (data, { deviceId }) => {
      // Update the documents list local cache
      queryClient.setQueryData(
        getDocumentsQueryKey(deviceId),
        (old: Document[]): Document[] => {
          return [...old, data];
        }
      );

      queryClient.setQueryData(getDocumentQueryKey(deviceId, data.id), data);

      queryClient.invalidateQueries({
        queryKey: getDocumentQueryKey(deviceId, data.id),
      });
      queryClient.invalidateQueries({
        queryKey: getDocumentsQueryKey(deviceId),
      });
    },
  });
};

export const useUploadFileMutation = () => {
  // const queryClient = useQueryClient();
  // Todo: Caching the file
  return useMutation({
    mutationFn: async ({ orgId, file }: { orgId: string; file: File }) => {
      const { data } = await uploadFile({ orgId, file });
      return FileSchema.validateSync(data);
    },
  });
};

export const useSaveAnswerMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      orgId,
      answer,
      step,
      documentVersion,
      answerFileId,
      deviceId,
    }: {
      orgId: string;
      docId: string;
      deviceId: string;
      step: TemplateElement;
      documentVersion: DocumentVersion;
      answerFileId?: string;
      answer?: string;
    }) => {
      const updatedDocVersion = await postDocumentAnswer(
        orgId,
        deviceId,
        documentVersion.documentId,
        documentVersion.id,
        {
          answer,
          element: step.id,
          answerFileId,
        }
      );
      return DocumentAnswerSchema.validateSync(updatedDocVersion.data);
    },
    onMutate: async ({
      docId,
      deviceId,
      answer,
      step,
      documentVersion,
      answerFileId,
    }) => {
      const previousDoc: Document | undefined = queryClient.getQueryData(
        getDocumentQueryKey(deviceId, docId)
      );
      const previousAnswer = documentVersion.answers.find(
        (a) => a.element === step.id
      );

      const newAnswer: DocumentAnswer = {
        documentVersionId: documentVersion.id,
        // Todo use id if the answer is already saved
        id: previousAnswer?.id || "temp",
        element: step.id,
        answer: answer,
        answerFileId: answerFileId,
        createdBy: previousAnswer?.createdBy || "temp",
        createdAt: previousAnswer?.createdAt || new Date(),
        updatedAt: new Date(),
      };

      const updatedDoc = {
        ...previousDoc,
        versions: previousDoc?.versions.map((version) => {
          if (version.id === documentVersion.id) {
            return {
              ...version,
              answers: [
                ...version.answers.filter(
                  (a: DocumentAnswer) => a.element !== step.id
                ),
                newAnswer,
              ],
            };
          } else {
            return version;
          }
        }),
      };

      queryClient.setQueryData(
        getDocumentQueryKey(deviceId, docId),
        updatedDoc
      );

      queryClient.setQueryData(
        getDocumentsQueryKey(deviceId),
        (old: Document[]) => [...old.filter((d) => d.id !== docId), updatedDoc]
      );
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: getDocumentQueryKey(variables.deviceId, variables.docId),
      });
      queryClient.invalidateQueries({
        queryKey: getDocumentsQueryKey(variables.deviceId),
      });
      // Optional return the invalidation to wait until both queries are invalidated
      // https://tkdodo.eu/blog/mastering-mutations-in-react-query#awaited-promises
    },
  });
};

export const useUpdateUserMutation = (orgId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, user }: { id: string; user: Partial<User> }) =>
      patchUserInOrg(orgId, id, user),
    onMutate: ({ user }) => {
      queryClient.setQueryData(getUserQueryKey(), (old: User) => ({
        ...old,
        ...user,
      }));
    },

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getOrganizationUsersQueryKey({ orgId }),
      });
      queryClient.invalidateQueries({
        queryKey: getUserQueryKey(),
      });
    },
  });
};

export const useUpdateOrgMutation = (orgId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ orgName }: { orgName: string }) =>
      updateOrg(orgId, { name: orgName }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getOrganizationUsersQueryKey({ orgId }),
      });
      queryClient.invalidateQueries({
        queryKey: getUserQueryKey(),
      });
    },
  });
};

export const useInviteUserMutation = (orgId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      inviteeEmail,
      inviteeFirstName,
      inviteeLastName,
    }: {
      inviteeEmail: string;
      inviteeFirstName: string;
      inviteeLastName: string;
    }) =>
      postUserInvite(orgId, {
        inviteeEmail,
        inviteeFirstName,
        inviteeLastName,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getUserQueryKey(),
      });
      queryClient.invalidateQueries({
        queryKey: getOrganizationUsersQueryKey({ orgId }),
      });
    },
  });
};

export const usePatchApproverStatusMutation = ({
  orgId,
  documentId,
  versionId,
  deviceId,
}: {
  orgId: string;
  documentId: string;
  versionId: string;
  deviceId: string;
}) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: getPatchApproverStatusMutationKey(),
    mutationFn: (data: {
      approverId: string;
      status: "USER_INVITE_SENT" | "AWAITING_SIGNATURE" | "APPROVED";
    }) =>
      patchApproverStatus({
        orgId,
        deviceId,
        documentId,
        versionId,
        status: data.status,
      }),
    onMutate: async (data) => {
      const previousData: DocumentVersionApprover[] | undefined =
        queryClient.getQueryData(getApproversQueryKey(documentId, versionId));

      if (previousData) {
        const nextData = previousData.map((approver) => {
          if (approver.id === data.approverId) {
            return { ...approver, approvalStatus: data.status };
          } else {
            return approver;
          }
        });

        // We update the local cache to reflect the new status
        queryClient.setQueryData(
          getApproversQueryKey(documentId, versionId),
          nextData
        );

        return { previousData };
      }
    },
    onError: (_, __, context) => {
      // If the mutation fails, we revert the local cache to the previous state
      if (context?.previousData) {
        queryClient.setQueryData(
          getApproversQueryKey(documentId, versionId),
          context.previousData
        );
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getApproversQueryKey(documentId, versionId),
      });
      queryClient.invalidateQueries({
        queryKey: getUserTodosQueryKey(deviceId),
      });
    },
  });
};

export const usePostApproverMutation = ({
  orgId,
  documentId,
  versionId,
  deviceId,
}: {
  orgId: string;
  documentId: string;
  versionId: string;
  deviceId: string;
}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: { userId: string }) =>
      postDocumentApprover({
        orgId,
        deviceId,
        documentId,
        versionId,
        data,
      }),
    onMutate: async (data) => {
      const previousData: DocumentVersionApprover[] | undefined =
        queryClient.getQueryData(getApproversQueryKey(documentId, versionId));

      if (previousData) {
        const nextData = [
          ...previousData,
          {
            id: "temp",
            user: {
              id: data.userId,
            },
            approvalStatus: "AWAITING_SIGNATURE",
          },
        ];

        queryClient.setQueryData(
          getApproversQueryKey(documentId, versionId),
          nextData
        );

        return { previousData };
      }
    },
    onError: (_, __, context) => {
      if (context?.previousData) {
        queryClient.setQueryData(
          getApproversQueryKey(documentId, versionId),
          context.previousData
        );
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getApproversQueryKey(documentId, versionId),
      }),
        queryClient.invalidateQueries({
          queryKey: getDocumentsQueryKey(deviceId),
        });
      queryClient.invalidateQueries({
        queryKey: getDocumentQueryKey(deviceId, documentId),
      });
    },
  });
};

export const useDeleteApproverMutation = ({
  orgId,
  documentId,
  versionId,
  deviceId,
}: {
  orgId: string;
  documentId: string;
  versionId: string;
  deviceId: string;
}) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: { approverId: string }) =>
      deleteDocumentApprover(
        orgId,
        deviceId,
        documentId,
        versionId,
        data.approverId
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getApproversQueryKey(documentId, versionId),
      });
      queryClient.invalidateQueries({
        queryKey: getDocumentsQueryKey(deviceId),
      });
      queryClient.invalidateQueries({
        queryKey: getDocumentQueryKey(deviceId, documentId),
      });
    },
  });
};

export const useCreateDocumentVersionMutation = (
  orgId: string,
  deviceId: string
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ docId, file }: { docId: string; file?: File }) => {
      const { data } = await createDocumentVersion(
        orgId,
        deviceId,
        docId,
        file
      );
      return DocumentVersionSchema.validateSync(data);
    },
    onSuccess: (data, { docId }) => {
      queryClient.invalidateQueries({
        queryKey: getDocumentQueryKey(deviceId, docId),
      });
      queryClient.invalidateQueries({
        queryKey: getDocumentsQueryKey(deviceId),
      });

      queryClient.invalidateQueries({
        queryKey: getUserTodosQueryKey(deviceId),
      });
      queryClient.setQueryData(
        getDocumentQueryKey(deviceId, docId),
        (old: Document) => {
          old.versions.push(data);
          return {
            ...old,
            versions: orderBy(old.versions, "createdAt", "desc"),
          };
        }
      );
      queryClient.setQueryData(
        getDocumentsQueryKey(deviceId),
        (old: Document[]) => {
          return old.map((doc) => {
            if (doc.id === docId) {
              return {
                ...doc,
                versions: orderBy([data, ...doc.versions], "createdAt", "desc"),
              };
            } else {
              return doc;
            }
          });
        }
      );
    },
  });
};

export const useCheckoutMutation = () => {
  return useMutation({
    mutationFn: async ({
      orgId,
      plan,
      amount,
      cycle,
    }: {
      orgId: string;
      plan: Plan.PREMIUM | Plan.PREMIUM_PLUS;
      amount: number;
      cycle: BillingInterval;
    }) => {
      const { data } = await checkout(orgId, plan, amount, cycle);
      return data as string;
    },
  });
};

export const useCreateBillingSessionMutation = () => {
  return useMutation({
    mutationFn: async (orgId: string) => {
      const { data } = await postBillingSession(orgId);
      return data as string;
    },
  });
};
