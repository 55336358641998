import { ChevronRight, ContentCopy, ReplayRounded } from "@mui/icons-material";
import {
  Button,
  IconButton,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { Device, TEMPLATE_TYPE } from "../../stores/models";
import {
  DocumentDataKey,
  TemplateElement,
  Suggestion as TSuggestion,
} from "../../types";

type Props = {
  suggestion: TSuggestion;
  step: TemplateElement;
  device: Device;
  handleApply: (stepId: DocumentDataKey, suggestion: string) => void;
  onRegenerateSuggestion: () => void;
};

export const Suggestion = ({
  step,
  handleApply,
  onRegenerateSuggestion,
  suggestion,
}: Props) => {
  const { templateId = "" } = useParams<{
    deviceId: string;
    templateId: TEMPLATE_TYPE;
  }>();
  if (!templateId) {
    throw new Error("No template id specified");
  }

  const [copySuccess, setCopySuccess] = useState(false);

  // const {
  //   value: localSuggestion,
  //   loading,
  //   isStatusMessage,
  //   statusMessageType,
  // } = suggestion;

  // const suggestionValue = getLocalOrSavedSuggestionValue(
  //   step,
  //   documentVersion,
  //   suggestion
  // );

  const copySuggestionToClipboard = async (suggestion: string) => {
    const blobHtml = new Blob([suggestion], { type: "text/html" });
    const blobText = new Blob([suggestion], { type: "text/plain" });
    const data = [
      new ClipboardItem({
        ["text/plain"]: blobText,
        ["text/html"]: blobHtml,
      }),
    ];

    navigator.clipboard.write(data);
    setCopySuccess(true);
    setTimeout(() => {
      setCopySuccess(false);
    }, 1000);
  };

  const hasError = suggestion.error;

  return (
    <div className="flex w-1/2  flex-col">
      <div className="flex items-center justify-between">
        <div className="flex flex-1 items-center justify-between">
          <Typography variant="body1" className="font-bold leading-[34px]">
            Suggestion
          </Typography>
          <div>
            <Tooltip title="Regenerate suggestion" placement="left">
              <IconButton size="small" onClick={onRegenerateSuggestion}>
                <ReplayRounded />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={copySuccess ? "Copied" : "Copy suggestion"}
              placement="left"
            >
              <IconButton
                size="small"
                onClick={() => copySuggestionToClipboard(suggestion.value)}
              >
                <ContentCopy />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </div>
      {hasError ? (
        <div className=" h-[60px] rounded bg-gray-100 px-3 py-4">
          <Typography className="text-red-500">
            Failed to load the suggestion. Please contact us if the issue
            persists.
          </Typography>
        </div>
      ) : !suggestion.value && suggestion.loading ? (
        <Skeleton
          variant="rectangular"
          height={60}
          className="rounded bg-gray-100"
        />
      ) : (
        <>
          <div className="border-md mb-4 rounded bg-gray-100 p-2">
            <div
              dangerouslySetInnerHTML={{
                __html: suggestion.value || "",
              }}
              className={`whitespace-pre-wrap ${
                suggestion.isStatusMessage &&
                suggestion.statusMessageType === "error"
                  ? "text-red-500"
                  : ""
              }`}
            />
          </div>
          {["textField", "markdownTable"].includes(step.element.type) && (
            <div className="flex self-end">
              <Button
                variant="contained"
                // size="small"
                endIcon={<ChevronRight />}
                color="success"
                disabled={suggestion.applied}
                onClick={() => handleApply(step.id, suggestion.value)}
              >
                Apply
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
};
