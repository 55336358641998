import config, {
  ASSISTANT_CONFIG,
  DEVICE_CHARACTERISTICS_STATEMENTS,
} from "@config";
import { Device, Document, DocumentVersion, TEMPLATE_TYPE } from "@models";
import { DocumentDataKey, TemplateElement } from "@types";
import { hasHardware, hasSoftware, isSoftwareOnly } from "@utils";
import { authHelper } from "src/stores/helpers";

export function generateSuggestion(
  templateId: TEMPLATE_TYPE,
  elementId: string,
  device: Device,
  documents: Document[],
  documentVersion: DocumentVersion,
  signal?: AbortSignal
) {
  const element = ASSISTANT_CONFIG[templateId as TEMPLATE_TYPE].elements.find(
    (e): e is TemplateElement => e.id === elementId && !e.hasOwnProperty("path")
  );
  if (!element) {
    throw new Error(
      `Element with id ${elementId} not found in template ${templateId}`
    );
  }

  const contextDict: Partial<Record<DocumentDataKey, string>> = {};

  // Get all answers from the documentVersion and all other documents
  const answers: DocumentVersion["answers"] = [];

  answers.push(
    ...documents
      .filter((d) => d.versions.length > 0)
      .map((d) => d.versions[0])
      // Filter out the current document and documents without versions
      .filter((v) => v.id !== documentVersion.id)
      .map((v) => v.answers)
      .flat()
  );

  answers.push(...documentVersion.answers);

  // If the context specifies elements that are not in the current document we search for them in the other documents
  answers.forEach((a) => {
    if (element.context?.includes(a.element))
      contextDict[a.element] = a.answer || "";
  });

  if (element.prePromptTransformerConfig) {
    // find the answer for the required input in all the document answers
    const inputs = answers
      .filter((a) =>
        element.prePromptTransformerConfig?.inputs.includes(a.element)
      )
      .map((a) => [a.element, a.answer]);

    if (
      !element.prePromptTransformerConfig.inputs.every((requiredInput) =>
        inputs.find(([dataKey]) => dataKey === requiredInput)
      )
    ) {
      throw new Error(
        "Could not find the saved answer for one of the required inputs of the prePromptTransformerConfig: " +
          JSON.stringify(inputs)
      );
    }

    try {
      // run the transformer
      const adjustedDataKeys = element.prePromptTransformerConfig.transformer(
        Object.fromEntries(inputs)
      );

      Object.entries(adjustedDataKeys).forEach(
        ([key, value]) => (contextDict[key as DocumentDataKey] = value)
      );
    } catch (error) {
      // return a generator that yields the error
      if (error instanceof Error) {
        return (function* () {
          yield `{ "message": "${error.message}", "status": "error"}`;
        })();
      }
    }
  }

  const context = Object.entries(contextDict).map(([k, v]) => ({
    id: k as DocumentDataKey,
    value: v,
  }));

  return fetchTemplateSuggestions(
    element,
    device,
    deviceCharacteristics(device),
    context,
    signal
  );
}

function deviceCharacteristics(device: Device): string[] {
  const statements = [];
  if (hasHardware(device))
    statements.push(DEVICE_CHARACTERISTICS_STATEMENTS.hasHardware);
  if (hasSoftware(device) && !isSoftwareOnly(device))
    statements.push(DEVICE_CHARACTERISTICS_STATEMENTS.hasSoftware);
  if (isSoftwareOnly(device))
    statements.push(DEVICE_CHARACTERISTICS_STATEMENTS.isSoftwareOnly);
  return statements;
}

export async function* fetchTemplateSuggestions(
  element: TemplateElement,
  device: Device,
  characteristics: string[],
  additionalContext?: Array<{ id: DocumentDataKey; value: String }>,
  signal?: AbortSignal
) {
  let response;
  try {
    response = await fetch(
      config.backends.assistant.baseUrl + "/template-suggestion/v2",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + (await authHelper.getAccessTokenSilently()()),
        },
        body: JSON.stringify({
          element_id: element.id,
          device_name: device.name,
          characteristics,
          components: device.components,
          description: device.description,
          additional_context: additionalContext,
        }),
        signal,
      }
    );
  } catch (error) {
    console.error(error);
  }

  var reader = response?.body?.getReader();
  var decoder = new TextDecoder("utf-8");

  if (!reader) throw new Error("Could not fetch suggestions. Reader is null");

  const previous = "";
  while (true) {
    const result = await reader.read();
    if (result === null) break;
    if (result.done) break;

    let token = decoder.decode(result.value);
    yield previous + token;
  }
}
