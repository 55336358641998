import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useStore } from "../../stores";
import { Device } from "../../stores/models";

type Props = {
  device: Device;
};

export const UpdateDeviceDescriptionModal: React.FC<Props> = (props) => {
  const { device } = props;
  const { orgId = "" } = useParams<{ orgId: string }>();
  const { deviceStore } = useStore();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [updatedDevice, setUpdatedDevice] = useState<Device | undefined>();

  return (
    <Dialog open={!device.description} onClose={() => null}>
      <DialogTitle>Device description</DialogTitle>
      <DialogContent className="flex  flex-col gap-y-4">
        <Alert severity="info">
          Please provide a brief description of your device. Please be as
          specific as possible. Every small detail like special features,
          materials or the production process can be essential in order to give
          you the best possible suggestions for the templates.
        </Alert>
        <TextField
          label="Description"
          fullWidth
          multiline
          rows={8}
          value={updatedDevice?.description ?? device.description}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            // Only have this if check here because ts is not picking up the undefined check at the top
            if (device) {
              setUpdatedDevice({
                ...device,
                description: event.target.value,
              });
            }
          }}
        />
      </DialogContent>
      <DialogActions className="justify-between">
        <Button
          color="error"
          onClick={() => {
            navigate(-1);
          }}
        >
          Cancel
        </Button>
        <LoadingButton
          disabled={!updatedDevice}
          loading={loading}
          variant="contained"
          color="success"
          onClick={() => {
            setLoading(true);
            updatedDevice && deviceStore.updateDevice(orgId, updatedDevice);
          }}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
