import { RoadmapCard, RoadmapStageId } from "@config";
import { useGetDevice, useGetDocuments, useGetTasks, useGetUser } from "@hooks";
import { Skeleton } from "@mui/material";
import { isTaskVisible } from "@utils";
import { useParams } from "react-router-dom";
import { TaskAccordion } from "src/components/Roadmap/Task/TaskAccordion";

type Props = {
  cardConfig: { card: RoadmapCard; phase: RoadmapStageId };
  deviceId: string;
  open: boolean;
  handleCloseCardDialog: () => void;
};

export const TaskContent = ({
  deviceId,
  cardConfig,
  handleCloseCardDialog,
}: Props) => {
  const { data: user } = useGetUser();
  const { orgId = "" } = useParams();
  const { data: tasks } = useGetTasks(orgId, deviceId);
  const { data: documents } = useGetDocuments(orgId, deviceId);
  const { data: device } = useGetDevice({ orgId, deviceId });

  if (tasks && documents && user && device)
    return (
      cardConfig.card.tasks
        // Only list tasks on the card where there is no visibility condition or where the condition is met
        .filter((task) => {
          return isTaskVisible(task, documents, device);
        })
        .map((task) => (
          <TaskAccordion
            key={task.id}
            task={task}
            handleCloseCardDialog={handleCloseCardDialog}
            documents={documents}
            user={user}
            device={device}
            deviceId={deviceId}
          />
        ))
    );

  return (
    <>
      <Skeleton
        variant="rectangular"
        key={0}
        width="100%"
        height={28}
        className="mb-2"
      />
      <Skeleton
        variant="rectangular"
        key={1}
        width="90%"
        height={28}
        className="mb-2"
      />
      <Skeleton
        variant="rectangular"
        key={2}
        width="100%"
        height={28}
        className="mb-2"
      />
    </>
  );
};
