import { EntitlementType } from "@models";
import { Button, Typography } from "@mui/material";
import { fillRouteWithSlugs, getSubscription } from "@utils";
import Lottie from "react-lottie";
import { Link, useParams } from "react-router-dom";
import ConfettiAnimation from "src/components/ConfettiAnimation/ConfettiAnimation";
import { ROUTES } from "src/config";
import { ROUTE_SLUGS } from "src/config/navigation/routes";
import { getOrgFromUser } from "src/utils/user";
import animationData from "../assets/highFive.json";
import { useGetUser } from "../hooks";
import { Loading } from "./Loading";

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
  duration: 2000,
};

export const CheckoutSuccess = () => {
  const { data: user } = useGetUser();
  const { orgId = "" } = useParams();
  const org = getOrgFromUser(user, orgId);
  const subscription = org ? getSubscription(org) : null;

  if (!subscription || !user || !org) {
    return <Loading />;
  }

  return (
    <div className="w-full h-full flex flex-col items-center justify-center">
      <Lottie options={defaultOptions} width={300} height={300} />
      <div className="pt-6">
        <div className="flex justify-between text-center flex-col items-center gap-3">
          <Typography variant="h1">High fives!</Typography>

          <Typography variant="body1" className="w-3/4">
            You just got one step closer to certifying your device!
          </Typography>
          {subscription.entitlements.find(
            (entitlement) => entitlement.type === EntitlementType.CONSULTING
          ) && (
            <Typography variant="body1" className="w-3/4">
              Our team will reach out to you as soon as possible to setup a
              meeting with our regulatory experts!
            </Typography>
          )}
          <Button
            variant="contained"
            component={Link}
            to={fillRouteWithSlugs(ROUTES.DEVICE_MANAGEMENT, {
              [ROUTE_SLUGS.ORG_ID]: orgId,
            })}
          >
            Let's go!
          </Button>
        </div>
      </div>
      <ConfettiAnimation />
    </div>
  );
};
