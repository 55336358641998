import {
  ComponentConfigurationType,
  ComponentType,
  Device,
  SubComponentType,
} from "@models";

export const hasSoftware = (device: Device): boolean => {
  return (
    device.components.some(
      (component) => component.type === ComponentType.SOFTWARE
    ) ||
    device.components.some((c) =>
      c.subComponents.some((sc) => sc.type === SubComponentType.SOFTWARE)
    )
  );
};

export const hasHardware = (device: Device): boolean => {
  return device.components.some(
    (component) => component.type === ComponentType.HARDWARE
  );
};

export const isSoftwareOnly = (device: Device): boolean => {
  return device.components.every(
    (component) => component.type === ComponentType.SOFTWARE
  );
};

export const hasSterileComponent = (device: Device): boolean => {
  return device.components.some(
    (c) =>
      c.configuration.some(
        (config) =>
          config.type ===
            ComponentConfigurationType.IS_STERILIZATION_INTENDED &&
          config.value === true
      ) ||
      device.components.some((c) =>
        c.subComponents.some((sc) =>
          sc.configuration.some(
            (config) =>
              config.type ===
                ComponentConfigurationType.IS_STERILIZATION_INTENDED &&
              config.value === true
          )
        )
      )
  );
};
