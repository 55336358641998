import { Device } from "@models";
import { hasSoftware } from "@utils";
import { ArticleId, RoadmapStageId, ROUTES } from "..";
import { CardContentType, DocumentDataKey } from "../../types";

export type RoadmapCardId =
  | ROADMAP_CARD_ID
  | "device-basic-characteristics"
  | "software-processes"
  | "establish-quality-management-system"
  | "device-classification"
  | "establish-risk-management-system"
  | "user-needs"
  | "software-design-and-development"
  | "purchasing-sales-and-suppliers"
  | "design-stage-checklists"
  | "usability-and-human-factors-engineering"
  | "clinical-investigations"
  | "software-verification-and-validation"
  | "complaints-and-feedback"
  | "training"
  | "testing-and-validation-stage-checklists"
  | "labeling-instructions-for-use-and-manuals"
  | "post-market-surveillance"
  | "clinical-evaluation"
  | "general-safety-and-performance-requirements-checklist"
  | "audits-and-review"
  | "declaration-of-conformity"
  | "udi-creation-and-eudamed-registration"
  | "generate-technical-file"
  | "software-release"
  | "software-validation-and-deployment";

export enum ROADMAP_CARD_ID {
  DEVICE_BASIC_CHARACTERISTICS = "device-basic-characteristics",
  SOFTWARE_PROCESSES = "software-processes",
  ESTABLISH_QUALITY_MANAGEMENT_SYSTEM = "establish-quality-management-system",
  DEVICE_CLASSIFICATION = "device-classification",
  ESTABLISH_RISK_MANAGEMENT_SYSTEM = "establish-risk-management-system",
  USER_NEEDS = "user-needs",
  SOFTWARE_DESIGN_AND_DEVELOPMENT = "software-design-and-development",
  PURCHASING_SALES_AND_SUPPLIERS = "purchasing-sales-and-suppliers",
  DESIGN_STAGE_CHECKLISTS = "design-stage-checklists",
  USABILITY_AND_HUMAN_FACTORS_ENGINEERING = "usability-and-human-factors-engineering",
  CLINICAL_INVESTIGATIONS = "clinical-investigations",
  SOFTWARE_VERIFICATION_AND_VALIDATION = "software-verification-and-validation",
  COMPLAINTS_AND_FEEDBACK = "complaints-and-feedback",
  TRAINING = "training",
  TESTING_AND_VALIDATION_STAGE_CHECKLISTS = "testing-and-validation-stage-checklists",
  LABELING_INSTRUCTIONS_FOR_USE_AND_MANUALS = "labeling-instructions-for-use-and-manuals",
  POST_MARKET_SURVEILLANCE = "post-market-surveillance",
  CLINICAL_EVALUATION = "clinical-evaluation",
  GENERAL_SAFETY_AND_PERFORMANCE_REQUIREMENTS_CHECKLIST = "general-safety-and-performance-requirements-checklist",
  AUDITS_AND_REVIEW = "audits-and-review",
  DECLARATION_OF_CONFORMITY = "declaration-of-conformity",
  UDI_CREATION_AND_EUDAMED_REGISTRATION = "udi-creation-and-eudamed-registration",
  GENERATE_TECHNICAL_FILE = "generate-technical-file",
  SOFTWARE_RELEASE = "software-release",
  SOFTWARE_VALIDATION_AND_DEPLOYMENT = "software-validation-and-deployment",
  INTERNAL_AUDITS = "internal-audits",
  PRODUCT_CHANGES = "product-changes",
  CAPAS_AND_VIGILANCE = "capas-and-vigilance",
  SURVEILLANCE_REPORTS = "surveillance-reports",
}
export type RoadmapCardConfig = {
  title: string;
  id: RoadmapCardId;
  enabled?: boolean;
  order?: number;
  stage: RoadmapStageId;
  link?: ROUTES;
  helpCenterArticleId?: ArticleId;
  visibilityCondition?: (
    availableDataKeys: {
      key: DocumentDataKey;
      value: string | undefined | null;
    }[],
    device: Device
  ) => boolean;
  tasksDescription: string;
  customContent?: CardContentType;
  techfileDescription?: string;
  afterTasksDescription?: string;
};

export const ROADMAP_CARDS: RoadmapCardConfig[] = [
  {
    title: "Establish Quality Management System",
    id: "establish-quality-management-system",
    stage: "planning",
    helpCenterArticleId: ArticleId.QualityManagementSystem,
    tasksDescription:
      "Every medical device company must have a quality management system (QMS). It defines how your company runs and dictates the processes you will have in place while you develop your medical device. The international standard used for creating a quality management system is ISO 13485:2016.\nSet up the basic SOPs and Quality Manual using the templates above. These won’t go into the technical file but are essential to have stored in your document storage system for auditorsMany of the other documents during this process come out of ISO 13485 like the post-market surveillance, design controls, and corrective and preventative actions. We will cover these later. For more information, refer to our guide.",
  },
  {
    title: "Device Basic Characteristics",
    id: "device-basic-characteristics",
    stage: "planning",
    helpCenterArticleId: ArticleId.DeviceBasicCharacteristics,
    tasksDescription:
      "Basic device characteristics should be determined in this step. What does your device do, the intended purpose, the intended patients, principles of operation, and conditions for use of the device. This will lay the groundwork for your device’s class and required regulatory documentation for certification.",
  },
  // {
  //   title: "Software Processes",
  //   id: "software-processes",
  //   stage: "planning",
  //   visibilityCondition: {
  //     usesSoftware: true,
  //   },
  //   tasksDescription:
  //     "The software process for how you will develop your software will be created here. These SOPs will dictate the process for creating, reviewing, testing, and releasing your software for your medical device. For now, we will save them in our document storage system and revisit them when we go through the software development process later on.",
  // },
  {
    title: "Device Classification",
    id: "device-classification",
    stage: "planning",
    helpCenterArticleId: ArticleId.DeviceClassification,
    tasksDescription:
      'Your medical device classification depends on the risk it can impart on the patient, with medical devices that potentially cause more risk being higher class. Medical devices in the EU can be Class I, Class IIa, Class IIb or Class III. <a href="https://health.ec.europa.eu/system/files/2021-10/mdcg_2021-24_en_0.pdf" target="_blank">Follow this guide</a> to determine what your medical device class should be (Chapter 4 has a nice flow chart you can use).  Your justification should use language from the flowchart.',
  },
  {
    title: "Establish Risk Management System",
    id: "establish-risk-management-system",
    stage: "design-and-development",
    helpCenterArticleId: ArticleId.RiskManagementSystem,
    tasksDescription:
      "You need to define what risks you expect the patients using your device will encounter and minimize those risks as far as possible. Risk management is governed by an international standard in a similar manner as the QMS called ISO 14971. The documentation you create will identify these risks and you can implement a plan to reduce them while you develop your product.",
  },
  {
    title: "User Needs",
    id: "user-needs",
    stage: "design-and-development",
    tasksDescription:
      "For this step we are creating user needs. These are essentially high level functions that your product should perform on/for a user. They will be used as a framework for how your software is built and serve as the backbone of what you are trying to build. It is ok if this list isn’t perfect, we can update it down the road.",
  },
  {
    title: "Software Design and Development",
    id: "software-design-and-development",
    stage: "design-and-development",
    enabled: true,
    visibilityCondition: (_, device) => hasSoftware(device),
    tasksDescription:
      "Now we will go through the process spelling out our software requirements and how we plan to develop and test our software product. We will outline our software architecture and designs which we will run through testing and validation in the later stages.",
  },
  {
    title: "Purchasing, Sales and Suppliers",
    id: "purchasing-sales-and-suppliers",
    stage: "design-and-development",
    tasksDescription:
      "Purchasing from suppliers and sale to customers are regulated to ensure that the customer is safe from misinformation and is receiving a product that lives up to the performance standards required of a medical device. These processes essentially vet your suppliers so you get a quality product and make sure you adhere to medical device law in your marketing and sales practices.",
  },
  {
    title: "Design Stage Checklists",
    id: "design-stage-checklists",

    order: 5,
    stage: "design-and-development",
    tasksDescription:
      "You have made it through the design and development stage! We are well on our way to developing our software so that it is compliant. When it comes to regulatory compliance, documentation is everything. In this last stage we will fill out checklists to ensure that everything we have done so far is in accordance with our Standard Operating Procedures (SOP) for quality and product. Once we complete our checklists we can move on to the next stage.",
  },
  {
    title: "Usability and Human Factors Engineering",
    id: "usability-and-human-factors-engineering",
    stage: "testing-and-validation",
    enabled: true,
    tasksDescription:
      "Usability and Human Factors Engineering makes sure that your users can use your device. Sounds simple right? You would be surprised at what your users do when they get ahold of your medical device.\n\nYou will need to test your product interface with some of your ideal users and watch them perform the tasks they need to perform. You often don’t need to test this in more than 15 users and you need to make sure that all critical tasks are performed (if there are any). Critical tasks are tasks that could result in serious harm.\n\nUsability testing is not the same as a clinical trial as you can perform it doing “simulated use” which means the device isn’t active but the users can still experience and interact with your device.",
  },
  // {
  //   title: "Clinical Investigations",
  //   id: "clinical-investigations",
  //   stage: "testing-and-validation",
  //   link: ROUTES.CLINICAL_EVALUATION,
  //   tasksDescription:
  //     "Most devices can use clinical data from products already on the market to support their clinical use and a clinical investigation is not required. If clinical investigations are required, you will want to outsource your trial to a contract research organization that will adhere to ISO 14155 which is an international standard for how clinical trials should be conducted. When the trial is completed, you will want to have the following documents created:\n<li>Clinical study protocol</li></li><li>Clinical study report</li><li>Ethics committee approval</li><li>Competent authority approval</li>Once these documents are completed they will feed directly into your clinical evaluation. You can fill in the relevant sections of the technical file when complete.",
  // },
  {
    title: "Software Verification and Validation",
    id: "software-verification-and-validation",
    stage: "testing-and-validation",
    tasksDescription:
      "Provide the results of your vigorous testing here! Your software testing should be flowing now along your software development plan. This stage ensures that as you implement your software into your system it performs as we intended it to.",
    visibilityCondition: (_, device) => hasSoftware(device),
  },
  // {
  //   title: "Complaints and Feedback",
  //   id: "complaints-and-feedback",
  //   stage: "testing-and-validation",
  //   enabled: true,
  //   tasksDescription:
  //     "When your product gets its CE mark, you will have to monitor to make sure no unexpected safety or performance issues arise. These SOPs make sure you have a process in place to do so. Use the assessment and notice forms whenever a safety event arises you need to report according to your SOP Vigilance.",
  // },
  {
    title: "Training",
    id: "training",
    stage: "testing-and-validation",
    enabled: true,
    tasksDescription:
      "As part of your quality management system, everyone at the company needs to be up to date on new SOPs and new information that will impact how they do their job and develop your product. It is your job to maintain records of training your employees according to document and record control and your SOP Human Resources and Training. These files will be a list of training provided to each of your employees.",
  },
  {
    title: "Testing and Validation Stage Checklists",
    id: "testing-and-validation-stage-checklists",
    stage: "testing-and-validation",
    enabled: true,
    order: 5,
    tasksDescription:
      "We made it to the end of testing and validation and we are ready for software release! Congratulations as this is a huge step towards finishing your CE marking preparation. Documentation of any final issues in your software and ensuring it is ready for release is critical step here. Also, finalizing your risk management for development is equally important.",
  },
  {
    title: "Labeling, Instructions for Use, and Manuals",
    id: "labeling-instructions-for-use-and-manuals",
    stage: "regulatory-preparation",
    tasksDescription:
      "Labeling, believe it or not, also has its own ISO Standards. There are required symbols that need to be included in your product labels in order to properly be marketed in the EU. Refer to ISO 15223-1 for the symbols to be used. For software or products delivered electronically, there is an e-labeling regulation 2021/2226 you need to adhere to.\n\nYour instructions for use need to include relevant information like your intended purpose, patient population, contraindications and warnings and need to have translations for all countries in which you plan to market your device. Follow the template we provided and you should be good to go! ",
  },
  {
    title: "Post-Market Surveillance",
    id: "post-market-surveillance",

    stage: "regulatory-preparation",
    tasksDescription:
      "Post-market surveillance is a way to track the safety and performance of your device after it has hit the market. You need to have a process in place to assess your devices evolving risks and patient benefits. Post-market surveillance reports should be created every year. The Periodic Safety Update Report is required every 2 years for Class IIA products and above. If you don’t have any clinical studies being performed while your device is on the market, you don’t need to fill out a Post-Market Clinical Follow-up Report.",
  },
  {
    title: "Clinical Evaluation",
    id: "clinical-evaluation",
    stage: "regulatory-preparation",
    enabled: true,
    tasksDescription:
      "The clinical evaluation demonstrates to the regulators that your product performs as intended when used clinically and is safe for use in humans. If you have not performed a clinical trial, the clinical evaluation is where you demonstrate that your device is equivalent to another device already on the market and therefore no clinical trial is required. You will need to do a review of the scientific literature and online vigilance databases to support your device’s safety and performance.",
  },
  {
    title: "General Safety and Performance Requirements Checklist",
    id: "general-safety-and-performance-requirements-checklist",
    stage: "regulatory-preparation",
    tasksDescription:
      "The General Safety and Performance Requirements come from the European MDR 2017/745 regulations and they spell out each specific requirement that your medical device needs to have depending on what the product itself does. The checklist serves as a way for auditors to find all of this information quickly and easily as they will need to verify all of the documentation to ensure that your product can go onto the market.",
  },
  {
    title: "Software Validation and Deployment",
    id: "software-validation-and-deployment",
    stage: "regulatory-preparation",
    tasksDescription:
      "The software validation and deployment stage is where you will perform your final software validation and deploy your software to the market. This is where you will generate your technical file and submit it to your Notified Body for review.",
  },
  {
    title: "Audits and Review",
    id: "audits-and-review",
    enabled: true,
    stage: "regulatory-preparation",
    tasksDescription:
      "Internal auditing and Management Review is a requirement for a compliant QMS. Now that your product has been documented as going through the compliant development and release process, we will audit your documentation to make sure nothing is missing.\n\nThe internal audit should be performed according to the SOP and will ensure that all of your processes will be followed. It will take half a day to do your internal audit most likely.\n\nNext, your management review is when the high level management reviews your processes and key performance indicators to make sure the QMS is functioning well. Once the internal audit and management review is complete, you are ready for the submission stage!",
  },
  {
    title: "Declaration of Conformity",
    id: "declaration-of-conformity",
    enabled: true,
    stage: "submission",
    tasksDescription:
      "Your declaration of conformity is a legally binding document that states that to your best knowledge you satisfy and conform to all the requirements for the CE mark.\n\nOnce you have completed all sections and have created your declaration of conformity, you can adhere your CE mark to your product and sell it on the market! Congratulations!",
  },
  {
    title: "UDI Creation and EUDAMED Registration",
    id: "udi-creation-and-eudamed-registration",
    stage: "submission",
    enabled: true,
    tasksDescription:
      "You must create a unique device identifier for your device and register it with the EUDAMED system which is an online registry of all medical devices CE marked in Europe.\n\nIf your device is Class I, you do not need to create a UDI until May of 2025 due to a grace period.",
  },
  {
    title: "Generate Technical File",
    id: "generate-technical-file",
    enabled: true,
    stage: "submission",
    tasksDescription:
      "Once your technical file is generated, you can download it and submit it to your Notified Body.",
    // customContent: CardContentType.GENERATE_TECHFILE,
  },
  {
    title: "Software Release",
    id: "software-release",
    enabled: true,
    stage: "submission",
    tasksDescription:
      "Before you can put your software on the market, you need to ensure that it goes through the proper software release processes first. This will be used to make sure our teams (and auditors) know that we checked to make sure we completed every prerequisite before releasing our new software product.",
    visibilityCondition: (_, device) => hasSoftware(device),
  },
  // INTERNAL_AUDITS = "internal-audits",
  // PRODUCT_CHANGES = "product-changes",
  // CAPAS_AND_VIGILANCE = "capas-and-vigilance",
  // SURVEILLANCE_REPORTS = "surveillance-reports",
  {
    title: "Internal Audits",
    id: ROADMAP_CARD_ID.INTERNAL_AUDITS,
    stage: "post-market",
    tasksDescription:
      "Internal audits are a requirement for a compliant QMS. Now that your product has been documented as going through the compliant development and release process, we will audit your documentation to make sure nothing is missing.",
  },
  {
    title: "Product Changes",
    id: ROADMAP_CARD_ID.PRODUCT_CHANGES,
    stage: "post-market",
    tasksDescription:
      "Product changes are inevitable as your product evolves. You need to have a process in place to document any changes to your product and ensure that you are compliant with the regulations.",
  },
  {
    title: "CAPAs and Vigilance",
    id: ROADMAP_CARD_ID.CAPAS_AND_VIGILANCE,
    stage: "post-market",
    tasksDescription:
      "Corrective and preventative actions (CAPAs) are a requirement for a compliant QMS. Now that your product has been documented as going through the compliant development and release process, we will audit your documentation to make sure nothing is missing.",
  },
  {
    title: "Surveillance Reports",
    id: ROADMAP_CARD_ID.SURVEILLANCE_REPORTS,
    stage: "post-market",
    tasksDescription:
      "Surveillance reports are a requirement for a compliant QMS. Now that your product has been documented as going through the compliant development and release process, we will audit your documentation to make sure nothing is missing.",
  },
];
