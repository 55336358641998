import { CheckCircleOutlined } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { useState } from "react";
import {
  BaseUser,
  DocumentVersion,
  DocumentVersionApprover,
} from "../../../../../stores/models";
import {
  formatDate,
  getApproverStatusText,
  userStatusDictionary,
} from "../utils";
import SectionContainer from "./SectionContainer";
import ApproverStatusChip from "./StatusChip";

export const CompleteScreen = ({
  approvers,
  revision,
  author,
  revisionSummary,
  docVersion,
}: {
  docVersion: DocumentVersion;
  approvers: DocumentVersionApprover[];
  revision: number;
  author: BaseUser;
  revisionSummary: string;
}) => {
  const [lastApprovalDate, setLastApprovalDate] = useState<Date>(new Date());

  approvers.forEach((approver) => {
    if (approver) {
      const date = new Date(approver.updatedAt);
      if (date > lastApprovalDate) {
        setLastApprovalDate(date);
      }
    }
  });

  const formData = {
    assignedUsers: approvers,
    revisionSummary: revisionSummary,
  };

  const getAuthorStatusText = (docVersion: DocumentVersion) => {
    const date = formatDate(new Date(docVersion.createdAt));
    return `Authored on ${date}`;
  };

  return (
    <div className="flex flex-col gap-2 pt-2 overflow-auto">
      <div className="flex items-center gap-2 my-2 border border-solid border-gray-200 rounded-md p-4 w-fit">
        <CheckCircleOutlined color="success" />
        <Typography variant="body1" className="font-bold">
          {`Revision ${revision} was approved on ${formatDate(lastApprovalDate)}`}
        </Typography>
      </div>
      <div className="flex flex-col gap-4">
        <SectionContainer
          title="Assignees"
          content={
            <div className="flex gap-3 w-full">
              {Object.values(formData.assignedUsers).map(
                (approver) =>
                  approver && (
                    <ApproverStatusChip
                      person={approver.user}
                      key={approver.id}
                      color={
                        userStatusDictionary[approver.approvalStatus].variant
                      }
                      text={getApproverStatusText(approver)}
                    />
                  )
              )}
            </div>
          }
        />
        <SectionContainer
          title="Author"
          content={
            <ApproverStatusChip
              person={author}
              text={getAuthorStatusText(docVersion)}
              color="success"
            />
          }
        />
        <SectionContainer
          title="Revision Summary"
          content={
            <Typography fontSize="body2">{formData.revisionSummary}</Typography>
          }
        />
      </div>
    </div>
  );
};

export default CompleteScreen;
