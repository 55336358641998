import { TextField } from "@mui/material";
import GenericModal from "../GenericModal/GenericModal";
import { InviteUserResponse } from "./hooks/useInviteUser";
import { UpdateOrgNameResponse } from "./hooks/useUpdateOrgName";
import { UpdateUserDataResponse } from "./hooks/useUpdateUserData";

export default function Modals({
  useUpdateOrgName,
  useInviteUser,
  useUpdateUserData,
}: {
  useUpdateOrgName: UpdateOrgNameResponse;
  useInviteUser: InviteUserResponse;
  useUpdateUserData: UpdateUserDataResponse;
}) {
  const {
    setOrgName,
    isOrgNameFilled,
    handleCloseChangeOrgNameModal,
    openChangeOrgNameModal,
    handleUpdateOrgName,
    oldName,
  } = useUpdateOrgName;

  const {
    setOpenInviteUserModal,
    invitedUser,
    setInvitedUser,
    areAllInviteUserInputsFilled,
    handleInviteUser,
    openInviteUserModal,
  } = useInviteUser;

  const {
    selectedUser,
    setUserToUpdate,
    areAllUpdateUserInputsFilled,
    setOpenUpdateUserModal,
    openUpdateUserModal,
    handleUpdateUser,
  } = useUpdateUserData;

  const genericModalDictionary = {
    changeOrgName: {
      title: "Organization Name",
      description: "Change the name of your organization",
      isDisabled: !isOrgNameFilled,
      handleSave: handleUpdateOrgName,
      handleCancel: handleCloseChangeOrgNameModal,
      isOpen: openChangeOrgNameModal,
      children: (
        <TextField
          label="Name"
          size="small"
          fullWidth
          defaultValue={oldName}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setOrgName(event.target.value)
          }
          style={{ padding: 0 }}
        />
      ),
    },
    inviteUser: {
      title: "Invite User",
      description: "Invite a new user to your organization",
      isDisabled: !areAllInviteUserInputsFilled,
      handleSave: handleInviteUser,
      handleCancel: () => setOpenInviteUserModal(false),
      isOpen: openInviteUserModal,
      children: (
        <div className="grid grid-cols-2 gap-3">
          <TextField
            label="First Name"
            size="small"
            fullWidth
            value={invitedUser.firstName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setInvitedUser({
                ...invitedUser,
                firstName: event.target.value,
              })
            }
            style={{ padding: 0 }}
          />
          <TextField
            label="Last Name"
            size="small"
            fullWidth
            value={invitedUser.lastName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setInvitedUser({
                ...invitedUser,
                lastName: event.target.value,
              })
            }
          />
          <TextField
            label="Email"
            size="small"
            fullWidth
            className="col-span-full"
            value={invitedUser.email}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setInvitedUser({
                ...invitedUser,
                email: event.target.value,
              })
            }
          />
        </div>
      ),
    },
    updateUser: {
      title: "Update User",
      description: `Update ${selectedUser?.firstName ? selectedUser.firstName : "user"}'s information`,
      isDisabled: !areAllUpdateUserInputsFilled,
      handleSave: () => handleUpdateUser({ userId: selectedUser?.id || "" }),
      handleCancel: () => setOpenUpdateUserModal(false),
      isOpen: openUpdateUserModal,
      children: (
        <div className="grid grid-cols-2 gap-3">
          <TextField
            label="First Name"
            size="small"
            fullWidth
            defaultValue={selectedUser?.firstName ?? ""}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setUserToUpdate({
                lastName: selectedUser?.lastName ?? "",
                firstName: event.target.value,
              });
            }}
            style={{ padding: 0 }}
          />
          <TextField
            label="Last Name"
            size="small"
            fullWidth
            defaultValue={selectedUser?.lastName ?? ""}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setUserToUpdate({
                firstName: selectedUser?.firstName ?? "",
                lastName: event.target.value,
              })
            }
          />
        </div>
      ),
    },
  };

  const openModal = Object.values(genericModalDictionary).filter(
    (modal) => modal.isOpen
  );

  return <GenericModal {...openModal[0]} />;
}
