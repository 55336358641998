import { DocumentAnswer, DocumentVersion } from "@models";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import { TemplateElement } from "@types";
import { useNavigate } from "react-router-dom";

export const WizardTopBar = ({
  activeStep,
  documentVersion,
  nextStep,
  prevStep,
  isFirstStep,
  isLastStep,
}: {
  activeStep: TemplateElement;
  documentVersion: DocumentVersion;
  filteredSteps: TemplateElement[];
  nextStep: () => void;
  prevStep: () => void;
  isFirstStep: boolean;
  isLastStep: boolean;
  deviceId: string;
}) => {
  const navigate = useNavigate();
  return (
    <div className="flex items-center  justify-between rounded-md bg-gray-100">
      <Button
        color="inherit"
        startIcon={<ChevronLeft />}
        onClick={() => (isFirstStep ? navigate(-2) : navigate(-1))}
      >
        Go Back
      </Button>
      <div className="flex gap-x-1">
        <IconButton onClick={prevStep} disabled={isFirstStep}>
          <ChevronLeft fontSize="small" />
        </IconButton>
        <IconButton
          onClick={nextStep}
          disabled={
            isLastStep ||
            !documentVersion.answers.find(
              (a: DocumentAnswer) => a.element === activeStep.id
            )
          }
        >
          <ChevronRight fontSize="small" />
        </IconButton>
      </div>
    </div>
  );
};
