import { useAuth0 } from "@auth0/auth0-react";
import { ROUTES } from "@config";
import { useGetUser } from "@hooks";
import { Plan } from "@models";
import { Dialog } from "@mui/material";
import * as Sentry from "@sentry/react";
import { fillRouteWithSlugs, getSubscription } from "@utils";
import { useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import { Banner } from "src/components/Layout/Banner";
import { ROUTE_SLUGS } from "src/config/navigation/routes";
import { getOrgFromUser } from "src/utils/user";
import { Fallback } from "..";
import Welcome from "../Onboarding/Welcome";
import { LeftDrawer } from "./LeftDrawer";

type Props = {
  children?: React.ReactNode;
};

export function MainLayout(props: Props) {
  const [isOnboardingOpen, setIsOnboardingOpen] = useState<boolean>(false);
  const { orgId } = useParams();
  const { isAuthenticated } = useAuth0();
  const { data: user } = useGetUser(isAuthenticated);
  const org = getOrgFromUser(user, orgId);
  const subscription = org ? getSubscription(org) : null;

  useEffect(() => {
    const shouldOpenOnboarding = localStorage.getItem("shouldOpenOnboarding");

    if (shouldOpenOnboarding === null || shouldOpenOnboarding === "true") {
      setIsOnboardingOpen(true);
    }
  }, []);

  useEffect(() => {
    const isUserMissingInfo = user && (!user?.firstName || !user?.lastName);

    if (isUserMissingInfo) {
      setIsOnboardingOpen(true);
    }
  }, [user]);

  const hasBanner = subscription?.products.includes(Plan.FREE);
  const isBannerVisible = !!hasBanner && !!subscription;

  return (
    <div className="flex h-full">
      {isBannerVisible && (
        <Banner
          bannerText={
            "Enjoying the free trial? Upgrade to a premium plan to complete your device's CE mark."
          }
          buttonText={"Upgrade plan"}
          buttonLink={fillRouteWithSlugs(ROUTES.SUBSCRIPTION, {
            [ROUTE_SLUGS.ORG_ID]: orgId,
          })}
        />
      )}

      <LeftDrawer hasBanner={isBannerVisible} />

      <div
        className={`flex flex-1 flex-col overflow-y-auto p-5 ${hasBanner ? "mt-[var(--banner-height)]" : ""}`}
      >
        <Sentry.ErrorBoundary fallback={(props) => <Fallback {...props} />}>
          {props.children || <Outlet />}
        </Sentry.ErrorBoundary>
      </div>

      {!!user && !!org && (
        <Dialog
          className="rounded"
          open={isOnboardingOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{
            "& .MuiPaper-root": {
              maxWidth: "100%",
            },
          }}
        >
          <Welcome
            user={user}
            org={org}
            setIsOnboardingOpen={setIsOnboardingOpen}
          />
        </Dialog>
      )}
    </div>
  );
}
