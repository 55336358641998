import { createContext, useContext } from "react";
import { DeviceStore } from "./device";
import { UIStore } from "./ui";

class RootStore {
  deviceStore: DeviceStore;
  uiStore: UIStore;

  constructor() {
    this.deviceStore = new DeviceStore(this);
    this.uiStore = new UIStore(this);

    if (import.meta.env.DEV) {
      (window as any).store = this;
    }
  }
}

export type RootStoreType = RootStore;

/**
 * The root store will be provided to the Provider
 */
export const rootStore = new RootStore();

/**
 * Enables us to access the store as hook inside components
 */
export function useStore() {
  const store = useContext(StoreContext);
  if (store === null) {
    throw new Error("Store cannot be null, please add a context provider");
  }
  return store;
}

export const StoreContext = createContext<RootStore | null>(null);
