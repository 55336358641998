import { SvgIconComponent } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import {
  TreeItem2Content,
  TreeItem2Root,
  TreeItemProps,
} from "@mui/x-tree-view";
import { useTreeItem2 } from "@mui/x-tree-view/useTreeItem2/useTreeItem2";
import { TemplateAssistant } from "../../../types";

export type DocExplorerLeafContentProps = Partial<TreeItemProps> & {
  template: TemplateAssistant;
  title: string;
  color?:
    | "disabled"
    | "inherit"
    | "action"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning";
  Icon: SvgIconComponent;
  highlightedText?: React.ReactNode;
};

export const DocExplorerLeafContent = ({
  template,
  title,
  disabled = false,
  Icon,
  color = "primary",
  highlightedText,
}: DocExplorerLeafContentProps) => {
  const { getContentProps } = useTreeItem2({
    itemId: template.id,
    label: template.docName,
    disabled: disabled,
  });

  return (
    <TreeItem2Root key={`${template.id}-template-tree-item`}>
      <TreeItem2Content
        {...getContentProps()}
        className="flex items-center gap-2"
        data-testid={`${template.id}-template-tree-item`}
      >
        <Tooltip title={title} key={`${template.id}-tooltip`}>
          <Icon fontSize="small" color={color} />
        </Tooltip>
        <span>{highlightedText || template.docName}</span>
      </TreeItem2Content>
    </TreeItem2Root>
  );
};
