import { RoadmapTaskId } from "@config";
import { Document, TEMPLATE_TYPE, User } from "@models";
import { getDocTypeStatus, isStatusComplete } from "@utils";

export const areAllCardTasksCompleted = (
  cardTasks: RoadmapTaskId[],
  cardDocuments: Document[],
  user: User,
  documents: Document[],
  orgId: string
) =>
  cardTasks.every((task) =>
    cardDocuments.some(
      (document) =>
        document.name === task &&
        isStatusComplete(
          getDocTypeStatus({
            templateId: document.name as TEMPLATE_TYPE,
            user: user,
            documents: documents,
            orgId,
          })
        )
    )
  );
