import {
  getTasksAsync,
  useCreateDocumentVersionMutation,
  useDocEngine,
  useGetDocument,
  usePatchDocumentVersionMutation,
  useTaskFinishedMutation,
} from "@hooks";
import { Device, Document, DocumentVersion, TEMPLATE_TYPE } from "@models";
import { useUpdateSearchParams } from "@utils";
import { orderBy } from "lodash";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";

export default function useSelectedDocumentVersion({
  documents,
  templateId,
  setUploadStatus,
  deviceId,
}: {
  documents: Document[] | undefined;
  templateId: TEMPLATE_TYPE;
  setUploadStatus?: (status: "error" | "idle" | "success" | "pending") => void;
  deviceId: string;
}) {
  const docEngine = useDocEngine(deviceId);
  const { orgId = "" } = useParams();
  const [searchParams] = useSearchParams();
  const updateSearchParams = useUpdateSearchParams();
  const docId = searchParams.get("d") || undefined;
  const { data: document } = useGetDocument(orgId, deviceId, docId);
  const patchDocumentMutation = usePatchDocumentVersionMutation();
  const taskFinishedMutation = useTaskFinishedMutation();

  const [loading, setLoading] = useState(false);
  const [selectedDocVersionId, setSelectedDocVersionId] = useState<
    string | undefined
  >(undefined);

  const selectedDoc = document;
  const selectedDocVersion = selectedDoc?.versions.find(
    (v) => v.id === selectedDocVersionId
  );

  useEffect(() => {
    if (document && !selectedDocVersion) {
      setSelectedDocVersionId(document.versions[0]?.id);
    }
  }, [selectedDocVersion, document]);

  /**
   * If no docId is provided in the url, select the latest document of the template type as default
   */
  useEffect(() => {
    const isNotDocProvided = documents && !docId;
    if (isNotDocProvided) {
      const doc = orderBy(
        documents.filter((doc) => doc.name === templateId),
        "createdAt",
        "desc"
      )[0];
      if (doc) {
        updateSearchParams({ d: doc.id });
      }
    }
  }, [documents, selectedDoc, selectedDocVersion, docId]);

  /**
   * Once the document is loaded, select it as the default document if there is no selected document
   */
  useEffect(() => {
    if (document && !selectedDoc) {
      selectDocument(document);
    }
  }, [document]);

  const createDocumentVersionMutation = useCreateDocumentVersionMutation(
    orgId,
    deviceId
  );

  const handleCreateNewVersionWithFile = async (
    selectedDoc: Document,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!e.target.files) return;

    setLoading(true);
    setUploadStatus && setUploadStatus("pending");
    const newVersion = await createDocumentVersionMutation.mutateAsync(
      {
        docId: selectedDoc.id,
        file: e.target.files[0],
      },
      {
        onError: () => {
          setUploadStatus && setUploadStatus("error");
          e.target.value = "";
          setLoading(false);
        },
        onSuccess: () => {
          setUploadStatus && setUploadStatus("success");
          setLoading(false);
        },
      }
    );

    await patchDocumentMutation.mutateAsync({
      orgId,
      docId: newVersion.documentId,
      deviceId: deviceId,
      docVersionId: newVersion.id,
      data: { readyForApproval: true },
    });

    const tasks = await getTasksAsync(orgId, deviceId);
    taskFinishedMutation.mutate({
      completed: true,
      tasks,
      deviceId,
      templateId,
      orgId,
    });

    setSelectedDocVersionId(newVersion.id);
  };

  // TODO - This is a temporary fix to set the latest version as default
  useEffect(() => {
    const latestVersion = selectedDoc?.versions[0]?.id;
    if (latestVersion && latestVersion !== selectedDocVersionId) {
      setSelectedDocVersionId(latestVersion);
    }
  }, [document?.versions]);

  const handleCreateNewVersion = async (
    selectedDoc: Document,
    device: Device,
    documents: Document[]
  ) => {
    setLoading(true);

    await docEngine.navigateToWizardOrQMS({
      templateId,
      createNewDocVersion: true,
      documentId: selectedDoc.id,
      device,
      documents,
    });

    setLoading(false);
  };

  const selectDocumentVersion = (docVersion: DocumentVersion) => {
    setSelectedDocVersionId(docVersion.id);
  };

  const selectDocument = (doc: Document) => {
    updateSearchParams({ d: doc.id });
    // Set the latest doc version as default
    selectDocumentVersion(doc.versions[0]);
  };

  return {
    selectLoading: loading,
    setLoading,
    selectedDocVersionId,
    setSelectedDocVersionId,
    selectedDocVersion,
    selectedDoc,
    handleCreateNewVersion,
    handleCreateNewVersionWithFile,
    selectDocumentVersion,
    selectDocument,
  };
}
