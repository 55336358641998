import {
  Inventory2Outlined,
  NoteAddOutlined,
  SchoolOutlined,
} from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { ROUTES } from "src/config";
import { ROUTE_SLUGS } from "src/config/navigation/routes";
import { fillRouteWithSlugs } from "src/utils/navigation";
export const DocLockedOverlay = () => {
  const { orgId = "" } = useParams();

  return (
    <div
      className="w-full backdrop-blur-sm bg-white/30 absolute bottom-0 left-0 top-44 flex align-center justify-center"
      id="doc-locked-overlay"
    >
      <div className="flex flex-col gap-7 w-2/4 bg-white h-fit mt-20 p-5 shadow-lg rounded border border-solid border-gray-200 sticky top-1/4">
        <Typography variant="h6" align="center">
          Oopsie, this document is not included in your free trial 😱
        </Typography>
        <div className="flex flex-col gap-4">
          <Typography textAlign="start" className="flex items-center gap-3">
            <NoteAddOutlined color="primary" /> Access to all generated
            documents.
          </Typography>
          <Typography textAlign="start" className="flex items-center gap-3">
            <SchoolOutlined color="primary" />
            1:1 expert support with our regulatory specialists.
          </Typography>
          <Typography textAlign="start" className="flex items-center gap-3">
            <Inventory2Outlined color="primary" /> Built in eQMS with
            streamlined document approval and revision handling.
          </Typography>
        </div>

        <Button
          variant="contained"
          component={Link}
          to={fillRouteWithSlugs(ROUTES.SUBSCRIPTION, {
            [ROUTE_SLUGS.ORG_ID]: orgId,
          })}
        >
          Upgrade
        </Button>
      </div>
    </div>
  );
};
