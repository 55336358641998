import { Box, List, ListSubheader, Skeleton } from "@mui/material";

export const LeftDrawerSkeleton = () => {
  return (
    <div className="flex flex-col justify-between h-screen sticky z-10 top-0 border-r border-t-0 border-b-0 border-l-0 border-solid border-slate-200">
      <Box>
        <List key="user-menu-list">
          <Skeleton
            variant="rectangular"
            height={37}
            width={152}
            className="mx-[6px] my-[4px]"
          />
        </List>
        <List
          key="platform-list"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              Platform
            </ListSubheader>
          }
        >
          <Skeleton
            variant="rectangular"
            height={37}
            width={152}
            className="mx-[6px] my-[4px]"
          />
        </List>
      </Box>
    </div>
  );
};
