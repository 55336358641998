import { useAuth0 } from "@auth0/auth0-react";
import { useGetUserTodos } from "@hooks";
import { AssignmentOutlined, Cloud, Route } from "@mui/icons-material";
import {
  Badge,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import { useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Todos } from "src/components/NavBar/todos/Todos";
import { ROUTE_SLUGS } from "src/config/navigation/routes";
import { fillRouteWithSlugs } from "src/utils/navigation";
import { ROUTES, theme } from "../../config";

const DeviceNavbar = () => {
  const { pathname } = useLocation();
  const { orgId = "", deviceId = "" } = useParams();
  const { isAuthenticated } = useAuth0();

  const { data: userTodos } = useGetUserTodos(
    orgId,
    deviceId,
    isAuthenticated && !!deviceId
  );

  const [anchorTodos, setAnchorTodos] = useState<HTMLElement | null>(null);
  const [areTodosOpen, setAreTodosOpen] = useState(false);

  const hasOutstandingActions =
    userTodos?.documentsTasks.toApprove.length !== 0 ||
    userTodos.documentsTasks.toAssignApprovers.length !== 0;

  const clickTodoIcon = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorTodos(event.currentTarget);
    setAreTodosOpen(!areTodosOpen);
  };

  const closeTodo = () => {
    setAnchorTodos(null);
    setAreTodosOpen(false);
  };

  return (
    <List
      key="device-list"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          Device
        </ListSubheader>
      }
    >
      <ListItemButton
        key="roadmap"
        className="gap-4"
        component={Link}
        to={fillRouteWithSlugs(ROUTES.DEVICE_OVERVIEW, {
          [ROUTE_SLUGS.ORG_ID]: orgId,
          [ROUTE_SLUGS.DEVICE_ID]: deviceId,
        })}
        state={{ previous: pathname }}
      >
        <ListItemIcon className="min-w-fit">
          <Route />
        </ListItemIcon>
        <ListItemText primary={"Roadmap"} />
      </ListItemButton>

      <ListItemButton
        key="qms"
        className="gap-4"
        component={Link}
        to={fillRouteWithSlugs(ROUTES.QMS, {
          [ROUTE_SLUGS.ORG_ID]: orgId,
          [ROUTE_SLUGS.DEVICE_ID]: deviceId,
        })}
        state={{ previous: pathname }}
      >
        <ListItemIcon className="min-w-fit">
          <Cloud />
        </ListItemIcon>
        <ListItemText primary={"QMS"} />
      </ListItemButton>
      {!!deviceId && userTodos?.documentsTasks && (
        <>
          <ListItemButton
            className="gap-4"
            onClick={clickTodoIcon}
            color="inherit"
            aria-describedby="todos-menu"
          >
            <Badge
              color="error"
              invisible={!hasOutstandingActions}
              badgeContent={
                userTodos?.documentsTasks?.toApprove.length +
                userTodos?.documentsTasks?.toAssignApprovers.length
              }
            >
              <ListItemIcon className="min-w-fit">
                <AssignmentOutlined
                  style={{ color: theme.palette.grey[500] }}
                />
              </ListItemIcon>
            </Badge>
            <ListItemText primary={"Todos"} />
          </ListItemButton>
          <Todos
            todos={userTodos.documentsTasks}
            isOpen={areTodosOpen}
            closeTodo={closeTodo}
            anchorTodos={anchorTodos}
            hasOutstandingActions={hasOutstandingActions}
          />
        </>
      )}
    </List>
  );
};

export default DeviceNavbar;
